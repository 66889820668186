import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import {AnyAction, configureStore} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// redux saga
import createSagaMiddleware from 'redux-saga';

import {rootReducer, rootPersistConfig} from 'store/rootReducer';
import {rootSaga} from 'store/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      // this is to pass the issue of "non-serialized" data.
      serializableCheck: false,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV === 'development',
});

// Run the redux saga
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
const useSelector = useReduxSelector;
const useDispatch = (): AnyAction => useReduxDispatch();

export {store, persistor, useSelector, useDispatch};
