import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  action: {
    id: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    create: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  actionList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
  },
};

// action slice
const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    // new action
    postActionStart: (state, reduxAction) => {},
    postActionPending: state => {
      state.action.create = {
        ...state.action.create,
        pending: true,
      };
    },
    postActionFail: (state, reduxAction) => {
      const {error} = reduxAction.payload;
      state.action.create = {
        ...state.action.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postActionSuccess: (state, reduxAction) => {
      const {data} = reduxAction.payload;
      state.action.create = {
        ...state.action.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostActionState: state => {
      state.action.create = initialState.action.create;
    },
    // update action
    putActionByIdStart: (state, reduxAction) => {
      state.action.update = initialState.action.update;
    },
    putActionByIdPending: state => {
      state.action.update = {
        ...state.action.update,
        pending: true,
      };
    },
    putActionByIdFail: (state, reduxAction) => {
      const {error} = reduxAction.payload;
      state.action.update = {
        ...state.action.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putActionByIdSuccess: (state, reduxAction) => {
      const {data} = reduxAction.payload;
      state.action.update = {
        ...state.action.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutActionState: state => {
      state.action.update = initialState.action.update;
    },
    // action listing
    getAllActionsStart: (state, reduxAction) => {
      state.actionList = {
        ...state.actionList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllActionsPending: state => {
      state.actionList = {
        ...state.actionList,
        pending: true,
      };
    },
    getAllActionsFail: (state, reduxAction) => {
      const {error} = reduxAction.payload;
      state.actionList = {
        ...state.actionList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllActionsSuccess: (state, reduxAction) => {
      const {data} = reduxAction.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (action, index) => {
          return _.extend(action, {order: index});
        }),
        'id'
      );

      state.actionList = {
        ...state.actionList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetActionListState: state => {
      state.actionList = initialState.actionList;
    },
    // action edit page
    getActionByIdStart: (state, reduxAction) => {
      state.action = initialState.action;
    },
    getActionByIdPending: state => {
      state.action.pending = true;
    },
    getActionByIdFail: (state, reduxAction) => {
      const {error} = reduxAction.payload;
      state.action = {
        ...state.action,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getActionByIdSuccess: (state, reduxAction) => {
      const {data} = reduxAction.payload;
      state.action = {
        ...state.action,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
  },
});

const {actions, reducer} = actionSlice;
export {actions, reducer};
