import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {companyApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// company listing page
function* getAllCompaniesSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = companyApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllCompaniesPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllCompaniesSuccess({data: response.data}));
    } else {
      yield put(actions.getAllCompaniesFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllCompaniesFail({error: {message: 'Something went wrong!'}}));
  }
}

// company listing page - delete company by id
function* deleteCompanyByIdSaga(action) {
  const {id} = action.payload;
  const endPoint = companyApi + '/' + id;
  yield put(actions.deleteCompanyByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    if (response.data.status === 'Association constraint error') {
      yield put(actions.deleteCompanyByIdFail({error: {message: response.data.message}}));
    } else {
      yield put(actions.deleteCompanyByIdSuccess({id}));
    }
  } else if (error) {
    // TODO: update this when back-end has a better structure of response error.
    yield put(actions.deleteCompanyByIdFail({error: {message: 'something went wrong'}}));
  }
}

// company new page
function* postCompanySaga(action) {
  const {postObj} = action.payload;
  const endPoint = companyApi;
  yield put(actions.postCompanyPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'post', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postCompanySuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postCompanyFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postCompanyFail({error: {message: 'something went wrong'}}));
  }
}

// company edit page
function* getCompanyByIdSaga(action) {
  const {id, includeAll} = action.payload;
  const endPoint = companyApi + '/' + id + (includeAll ? '?include_all=true' : '');

  yield put(actions.getCompanyByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.getCompanyByIdSuccess({data: response.data}));
    } else {
      yield put(actions.getCompanyByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getCompanyByIdFail({error: {message: 'something went wrong'}}));
  }
}

// company edit page - update company by id
function* putCompanyByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = companyApi + '/' + id;

  yield put(actions.putCompanyByIdPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'put', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putCompanyByIdSuccess({data: response.data}));
    } else {
      yield put(actions.putCompanyByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.putCompanyByIdFail({error: {message: 'something went wrong'}}));
  }
}

export function* companySaga(): Generator<ForkEffect<never>, void, unknown> {
  // company listing page
  yield takeLatest(actions.getAllCompaniesStart.type, getAllCompaniesSaga);
  // company listing page - delete company by id
  yield takeEvery(actions.deleteCompanyByIdStart.type, deleteCompanyByIdSaga);
  // company new page
  yield takeEvery(actions.postCompanyStart.type, postCompanySaga);
  // company edit page
  yield takeLatest(actions.getCompanyByIdStart.type, getCompanyByIdSaga);
  // company edit page - update company by id
  yield takeEvery(actions.putCompanyByIdStart.type, putCompanyByIdSaga);
}
