import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  company: {
    id: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    create: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  // companyPhoto: {
  //   pending: false,
  //   data: null,
  //   error: null,
  //   success: false,
  //   update: {
  //     pending: false,
  //     data: null,
  //     error: null,
  //     success: false,
  //   },
  //   create: {
  //     pending: false,
  //     data: null,
  //     error: null,
  //     success: false,
  //   },
  // },
  companyList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    delete: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
  },
};

// company slice
const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // new company
    postCompanyStart: (state, action) => {},
    postCompanyPending: state => {
      state.company.create = {
        ...state.company.create,
        pending: true,
      };
    },
    postCompanyFail: (state, action) => {
      const {error} = action.payload;
      state.company.create = {
        ...state.company.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postCompanySuccess: (state, action) => {
      const {data} = action.payload;
      state.company.create = {
        ...state.company.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostCompanyState: state => {
      state.company.create = initialState.company.create;
    },
    // new photo

    // update company
    putCompanyByIdStart: (state, action) => {
      state.company.update = initialState.company.update;
    },
    putCompanyByIdPending: state => {
      state.company.update = {
        ...state.company.update,
        pending: true,
      };
    },
    putCompanyByIdFail: (state, action) => {
      const {error} = action.payload;
      state.company.update = {
        ...state.company.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putCompanyByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.company.update = {
        ...state.company.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutCompanyState: state => {
      state.company.update = initialState.company.update;
    },
    // delete company
    deleteCompanyByIdStart: (state, action) => {
      const {id} = action.payload;
      state.companyList.delete = {
        ...state.companyList.delete,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    deleteCompanyByIdPending: state => {
      state.companyList.delete = {
        ...state.companyList.delete,
        pending: true,
      };
    },
    deleteCompanyByIdFail: (state, action) => {
      const {error} = action.payload;
      state.companyList.delete = {
        ...state.companyList.delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteCompanyByIdSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.companyList.items};
      delete updatedItems[id];

      state.companyList = {
        ...state.companyList,
        items: updatedItems,
      };

      state.companyList.delete = {
        ...state.companyList.delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // company listing
    getAllCompaniesStart: (state, action) => {
      state.companyList = {
        ...state.companyList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllCompaniesPending: state => {
      state.companyList = {
        ...state.companyList,
        pending: true,
      };
    },
    getAllCompaniesFail: (state, action) => {
      const {error} = action.payload;
      state.companyList = {
        ...state.companyList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllCompaniesSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.companyList = {
        ...state.companyList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetCompanyListState: state => {
      state.companyList = initialState.companyList;
    },
    // company edit page
    getCompanyByIdStart: (state, action) => {
      state.company = initialState.company;
    },
    getCompanyByIdPending: state => {
      state.company.pending = true;
    },
    getCompanyByIdFail: (state, action) => {
      const {error} = action.payload;
      state.company = {
        ...state.company,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getCompanyByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.company = {
        ...state.company,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
  },
});

const {actions, reducer} = companySlice;
export {actions, reducer};
