import React from 'react';
import {Icon, IconifyIcon} from '@iconify/react';
import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {alpha} from '@material-ui/core/styles';
import {Button, Box, Divider, MenuItem, Typography, Backdrop} from '@material-ui/core';
// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {useSelector, RootStateOrAny} from 'react-redux';
// components
import {MIconButton} from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

// icons
import {homeOutline, userOutline, cardOutline, settingOutline} from '../../icons';

// ----------------------------------------------------------------------

interface OptionProps {
  label: string;
  icon: any;
  linkTo: string;
  disable?: boolean;
}

const MENU_OPTIONS: OptionProps[] = [
  {
    label: 'Dashboard',
    icon: homeOutline,
    linkTo: PATH_DASHBOARD.home,
  },
  {
    label: 'Settings',
    icon: settingOutline,
    linkTo: PATH_DASHBOARD.settings.profile,
  },
  // {
  //   label: 'Personal',
  //   icon: userOutline,
  //   linkTo: PATH_DASHBOARD.settings.personal,
  //   disable: true,
  // },
  // {
  //   label: 'Payment',
  //   icon: cardOutline,
  //   linkTo: PATH_DASHBOARD.settings.payment,
  //   disable: true,
  // },
];

// ----------------------------------------------------------------------

const AccountPopover: React.FC = () => {
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const {user, onLogout} = useAuth();
  const [open, setOpen] = useState(false);

  // redux
  const pendingTemplateRequest = useSelector(
    ({template}: RootStateOrAny) => template.template.requests.pendingCount
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    onLogout();
    if (isMountedRef.current) {
      handleClose();
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 220}}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          {user !== null && (
            <>
              <Typography variant="subtitle1" noWrap>
                {user.displayName}
              </Typography>
              <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                {user.email}
              </Typography>
            </>
          )}
        </Box>

        <Divider sx={{my: 1}} />

        <Box sx={{position: 'relative'}}>
          {MENU_OPTIONS.map((option: OptionProps) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{typography: 'body2', py: 1, px: 2.5}}
              disabled={option.disable ? option.disable : false}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}

          <Box sx={{p: 2, pt: 1.5}}>
            <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
              Logout
            </Button>
          </Box>

          <Backdrop
            open={pendingTemplateRequest !== 0}
            sx={{
              position: 'absolute',
              background: 'rgba(255, 255, 255, 0.5)',
            }}
          />
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
