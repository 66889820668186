import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {actionApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

// action listing page
function* getAllActionsSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = actionApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllActionsPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllActionsSuccess({data: response.data}));
    } else {
      yield put(actions.getAllActionsFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllActionsFail({error: {message: 'Something went wrong!'}}));
  }
}

// inspection editing page - create new action
function* postActionSaga(action) {
  const {postObj} = action.payload;
  const endPoint = actionApi;
  yield put(actions.postActionPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'post', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postActionSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postActionFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postActionFail({error: {message: 'something went wrong'}}));
  }
}

// action edit page
function* getActionByIdSaga(action) {
  const {id, includeAll} = action.payload;
  const endPoint = actionApi + '/' + id + (includeAll ? '?include_all=true' : '');

  yield put(actions.getActionByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.getActionByIdSuccess({data: response.data}));
    } else {
      yield put(actions.getActionByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getActionByIdFail({error: {message: 'something went wrong'}}));
  }
}

// action edit page - update action by id
function* putActionByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = actionApi + '/' + id;

  yield put(actions.putActionByIdPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'put', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putActionByIdSuccess({data: response.data}));
    } else {
      yield put(actions.putActionByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.putActionByIdFail({error: {message: 'something went wrong'}}));
  }
}

export function* actionSaga(): Generator<ForkEffect<never>, void, unknown> {
  // action listing page
  yield takeLatest(actions.getAllActionsStart.type, getAllActionsSaga);
  // inspection page - create action
  yield takeEvery(actions.postActionStart.type, postActionSaga);
  // action edit page
  yield takeLatest(actions.getActionByIdStart.type, getActionByIdSaga);
  // action edit page - update action by id
  yield takeEvery(actions.putActionByIdStart.type, putActionByIdSaga);
}
