import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  role: {
    id: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    create: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  roleList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    delete: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
  },
};

// role slice
const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // new role
    postRoleStart: (state, action) => {},
    postRolePending: state => {
      state.role.create = {
        ...state.role.create,
        pending: true,
      };
    },
    postRoleFail: (state, action) => {
      const {error} = action.payload;
      state.role.create = {
        ...state.role.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postRoleSuccess: (state, action) => {
      const {data} = action.payload;
      state.role.create = {
        ...state.role.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostRoleState: state => {
      state.role.create = initialState.role.create;
    },

    // update role
    putRoleByIdStart: (state, action) => {
      state.role.update = initialState.role.update;
    },
    putRoleByIdPending: state => {
      state.role.update = {
        ...state.role.update,
        pending: true,
      };
    },
    putRoleByIdFail: (state, action) => {
      const {error} = action.payload;
      state.role.update = {
        ...state.role.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putRoleByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.role.update = {
        ...state.role.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutRoleState: state => {
      state.role.update = initialState.role.update;
    },
    // delete role
    deleteRoleByIdStart: (state, action) => {
      const {id} = action.payload;
      state.roleList.delete = {
        ...state.roleList.delete,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    deleteRoleByIdPending: state => {
      state.roleList.delete = {
        ...state.roleList.delete,
        pending: true,
      };
    },
    deleteRoleByIdFail: (state, action) => {
      const {error} = action.payload;
      state.roleList.delete = {
        ...state.roleList.delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteRoleByIdSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.roleList.items};
      delete updatedItems[id];

      state.roleList = {
        ...state.roleList,
        items: updatedItems,
      };

      state.roleList.delete = {
        ...state.roleList.delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // role listing
    getAllRolesStart: (state, action) => {
      state.roleList = {
        ...state.roleList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllRolesPending: state => {
      state.roleList = {
        ...state.roleList,
        pending: true,
      };
    },
    getAllRolesFail: (state, action) => {
      const {error} = action.payload;
      state.roleList = {
        ...state.roleList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllRolesSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.roleList = {
        ...state.roleList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetRoleListState: state => {
      state.roleList = initialState.roleList;
    },
    // role edit page
    getRoleByIdStart: (state, action) => {
      state.role = initialState.role;
    },
    getRoleByIdPending: state => {
      state.role.pending = true;
    },
    getRoleByIdFail: (state, action) => {
      const {error} = action.payload;
      state.role = {
        ...state.role,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getRoleByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.role = {
        ...state.role,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
  },
});

const {actions, reducer} = roleSlice;
export {actions, reducer};
