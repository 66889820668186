import {combineReducers} from 'redux';
// import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import our reducer types
import {AuthStateType} from 'store/auth/types';
import {RegistrationStateType} from 'store/register/types';

// import our reducers
import {reducer as generalReducer} from 'store/general/slice';
import {reducer as authReducer} from 'store/auth/slice';
import {reducer as registerReducer} from 'store/register/slice';
import {reducer as templateReducer} from 'store/template/slice';
import {reducer as locationReducer} from 'store/location/slice';
import {reducer as companyReducer} from 'store/company/slice';
import {reducer as inspectionReducer} from 'store/inspection/slice';
import {reducer as reportReducer} from 'store/report/slice';
import {reducer as actionReducer} from 'store/action/slice';
import {reducer as userReducer} from 'store/user/slice';
import {reducer as roleReducer} from 'store/role/slice';
import {reducer as accSettingsReducer} from 'store/accountSettings/slice';
import {reducer as profileSettingsReducer} from 'store/profileSettings/slice';
import {reducer as subscriptionReducer} from 'store/subscription/slice';
import {reducer as permissionReducer} from 'store/permission/slice';
// update both RootState and rootReducer when needed
export interface RootState {
  // TODO: type this
  general: any;
  auth: AuthStateType;
  register: RegistrationStateType;
  // TODO: type this
  template: any;
  // TODO: type this
  location: any;
  // TODO: type this
  company: any;
  // TODO: type this
  inspection: any;
  // TODO: type this
  report: any;
  // TODO: type this
  action: any;
  // TODO: type this
  user: any;
  // TODO: type this
  role: any;
  // TODO: type this
  accSettings: any;
  // TODO: type this
  profileSettings: any;
  // TODO: type this
  subscription: any;
  // TODO: type this
  permission: any;
}

// update both RootState and rootReducer when neede
export const rootReducer = combineReducers<RootState>({
  general: generalReducer,
  auth: authReducer,
  register: registerReducer,
  template: templateReducer,
  location: locationReducer,
  company: companyReducer,
  inspection: inspectionReducer,
  report: reportReducer,
  action: actionReducer,
  user: userReducer,
  role: roleReducer,
  accSettings: accSettingsReducer,
  profileSettings: profileSettingsReducer,
  subscription: subscriptionReducer,
  permission: permissionReducer,
});

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
