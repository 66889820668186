import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {profileSettingsApi, passwordSettingsApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// profile settings page - personal settings
function* putPersonalSettingsSaga(action) {
  const {postObj} = action.payload;
  const endPoint = profileSettingsApi;

  yield put(actions.putPersonalSettingsPending());
  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putPersonalSettingsSuccess({data: response.data}));
    } else {
      yield put(
        actions.putPersonalSettingsFail({error: {message: 'something went wrong'}})
      );
    }
  } else if (error) {
    yield put(
      actions.putPersonalSettingsFail({error: {message: 'something went wrong'}})
    );
  }
}

// profile settings page - password settings
function* putPasswordSettingsSaga(action) {
  const postObj = action.payload;
  const endPoint = passwordSettingsApi;

  yield put(actions.putPasswordSettingsPending());
  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putPasswordSettingsSuccess({data: response.data}));
    } else if (response?.data?.status === 'fail' && response.data.message) {
      yield put(
        actions.putPasswordSettingsFail({error: {message: response.data.message}})
      );
    }
  } else if (error) {
    yield put(
      actions.putPasswordSettingsFail({error: {message: 'something went wrong'}})
    );
  }
}

export function* profileSettingsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actions.putPersonalSettingsStart.type, putPersonalSettingsSaga);
  yield takeLatest(actions.putPasswordSettingsStart.type, putPasswordSettingsSaga);
}
