import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RegistrationStateType} from './types';

const initialState: RegistrationStateType = {
  registerationuser: {
    id: '',
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    password: '',
    verifycode: 0,
    token: '',
  },
  registerStatus: {
    loading: false,
    emailError: null,
    verifyCodeError: null,
    detailError: null,
    success: false,
  },
  registerFormCurrentStep: 0,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    postRegisterEmailStart: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
      };
    },
    postRegisterEmailFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        emailError: error,
      };
    },
    postRegisterEmailSuccess: (state, action) => {
      const {email, id} = action.payload;
      state.registerationuser = {
        ...state.registerationuser,
        id,
        email,
      };
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        emailError: null,
      };
      state.registerFormCurrentStep = state.registerFormCurrentStep + 1;
    },
    postEmailVerificationStart: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
      };
    },
    postEmailVerificationSuccess: (state, action) => {
      const {token} = action.payload;
      state.registerationuser = {
        ...state.registerationuser,
        token,
      };
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        verifyCodeError: null,
      };
      state.registerFormCurrentStep = state.registerFormCurrentStep + 1;
    },
    postEmailVerificationFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        verifyCodeError: error,
      };
    },
    postRegisterUserDetailStart: (state, action) => {
      const {first_name, last_name, company} = action.payload;
      state.registerationuser = {
        ...state.registerationuser,
        firstname: first_name,
        lastname: last_name,
        company: company,
      };
      state.registerStatus = {
        ...state.registerStatus,
        loading: true,
        success: false,
      };
    },
    postRegisterUserDetailSuccess: (state, action) => {
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        success: true,
      };
    },
    postRegisterUserDetailFail: (state, action) => {
      const {error} = action.payload;
      state.registerStatus = {
        ...state.registerStatus,
        loading: false,
        detailError: error,
        success: false,
      };
    },
    postPlanChooseStart: (state, action) => {
      state.registerFormCurrentStep = state.registerFormCurrentStep + 1;
    },
    postNavigatePrevious: (state, action) => {
      state.registerFormCurrentStep = state.registerFormCurrentStep - 1;
    },
    resetRegistrationState: state => initialState,
  },
});

const {actions, reducer} = registerSlice;

export {actions, reducer};
