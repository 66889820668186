import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  general: {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  },
};

// general slice
const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // new company
    initSwSuccess: state => {
      state.general.serviceWorkerInitialized = true;
    },
    updateSwSuccess: (state, action) => {
      state.general.serviceWorkerUpdated = true;
      state.general.serviceWorkerRegistration = action.payload;
    },
  },
});

const {actions, reducer} = generalSlice;
export {actions, reducer};
