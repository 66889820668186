import React, {useEffect, useState} from 'react';
import {useSelector, RootStateOrAny} from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DialogNewAppVersion: React.FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const hasNewVersion = useSelector(
    ({general}: RootStateOrAny) => general.general.serviceWorkerUpdated
  );
  const swr = useSelector(
    ({general}: RootStateOrAny) => general.general.serviceWorkerRegistration
  );

  // hooks
  useEffect(() => {
    if (hasNewVersion && swr) {
      setIsOpen(true);
    }
  }, [hasNewVersion, swr]);

  // component events
  const handleUpdateClick = () => {
    const swrWaiting = swr.waiting;
    if (swrWaiting) {
      swrWaiting.postMessage({type: 'SKIP_WAITING'});
      swrWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
    // get the 'reg' from service worker
    setIsOpen(false);
  };

  const handleClose = (e, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      // do nothing
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{pb: 2}}>New Version available !</DialogTitle>
      <DialogContent sx={{pb: 0}}>
        <DialogContentText id="alert-dialog-description">
          There is a newer version of the app since your last visit.
          <br />
          We will update your app to latest version.
          {/* Please click &quot;Update&quot; below to update to latest version. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdateClick} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNewAppVersion;
