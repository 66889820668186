import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {locationApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

// location listing page
function* getAllLocationsSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = locationApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllLocationsPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllLocationsSuccess({data: response.data}));
    } else {
      yield put(actions.getAllLocationsFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllLocationsFail({error: {message: 'Something went wrong!'}}));
  }
}

// location listing page - delete location by id
function* deleteLocationByIdSaga(action) {
  const {id} = action.payload;
  const endPoint = locationApi + '/' + id;
  yield put(actions.deleteLocationByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    if (response.data.status === 'Association constraint error') {
      yield put(
        actions.deleteLocationByIdFail({error: {message: response.data.message}})
      );
    } else {
      yield put(actions.deleteLocationByIdSuccess({id}));
    }
  } else if (error) {
    yield put(actions.deleteLocationByIdFail({error: {message: 'something went wrong'}}));
  }
}

// location new page
function* postLocationSaga(action) {
  const {postObj} = action.payload;
  const endPoint = locationApi;
  yield put(actions.postLocationPending());
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postLocationSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postLocationFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postLocationFail({error: {message: 'something went wrong'}}));
  }
}

// location edit page
function* getLocationByIdSaga(action) {
  const {id, includeAll} = action.payload;
  const endPoint = locationApi + '/' + id + (includeAll ? '?include_all=true' : '');

  yield put(actions.getLocationByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getLocationByIdSuccess({data: response.data}));
    } else {
      yield put(actions.getLocationByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getLocationByIdFail({error: {message: 'something went wrong'}}));
  }
}

// location edit page - update location by id
function* putLocationByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = locationApi + '/' + id;

  yield put(actions.putLocationByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    if (response.data.data) {
      yield put(actions.putLocationByIdSuccess({data: response.data}));
    } else {
      yield put(actions.putLocationByIdFail({error: 'something went wrong'}));
    }
  } else if (error) {
    yield put(actions.putLocationByIdFail({error: 'something went wrong'}));
  }
}

export function* locationSaga(): Generator<ForkEffect<never>, void, unknown> {
  // location listing page
  yield takeLatest(actions.getAllLocationsStart.type, getAllLocationsSaga);
  // location listing page - delete location by id
  yield takeEvery(actions.deleteLocationByIdStart.type, deleteLocationByIdSaga);
  // location new page
  yield takeEvery(actions.postLocationStart.type, postLocationSaga);
  // location edit page
  yield takeLatest(actions.getLocationByIdStart.type, getLocationByIdSaga);
  // location edit page - update location by id
  yield takeEvery(actions.putLocationByIdStart.type, putLocationByIdSaga);
}
