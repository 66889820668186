import {useRef, useEffect} from 'react';

// ----------------------------------------------------------------------

const useIsMountedRef = (): React.MutableRefObject<boolean> => {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
};

export default useIsMountedRef;
