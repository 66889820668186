import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {permissionApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

function* getAllPermissionSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = permissionApi + '/' + (includeAll ? 'include_all=true' : '');

  yield put(actions.getAllPermissionPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    if (response.data) {
      yield put(actions.getAllPermissionSuccess({data: response.data}));
    } else {
      yield put(actions.getAllPermissionFail({error: {message: 'Something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getAllPermissionFail({error: {message: 'Something went wrong'}}));
  }
}

export function* permissonSaga(): Generator<ForkEffect<never>, void, unknown> {
  //listing
  yield takeLatest(actions.getAllPermissionStart.type, getAllPermissionSaga);
}
