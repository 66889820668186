import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  subscriptionList: {
    items: null,
    pending: false,
    error: null,
    success: false,
  },
};

// subscription slice
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // subscription setting page
    getAllSubscriptionsStart: (state, action) => {
      state = initialState;
    },
    getAllSubscriptionsPending: state => {
      state.subscriptionList.pending = true;
    },
    getAllSubscriptionsFail: (state, action) => {
      const {error} = action.payload;

      state.subscriptionList = {
        ...state.subscriptionList,
        pending: false,
        items: null,
        error: error,
        success: false,
      };
    },
    getAllSubscriptionsSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.subscriptionList = {
        ...state.subscriptionList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
      };
    },
    resetSubscriptionListState: state => {
      state.subscriptionList = initialState.subscriptionList;
    },
  },
});

const {actions, reducer} = subscriptionSlice;
export {actions, reducer};
