import jwtDecode from 'jwt-decode';
import {verify, sign} from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

// TODO: need to know exactly what's in the access token later
interface decodedType {
  userId: string;
  exp: number;
  iat: number;
  // whatever else is in the token.
}

// const isValidToken = (accessToken: string): boolean => {
//   if (!accessToken) {
//     return false;
//   }

//   const decoded = jwtDecode<decodedType>(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decoded.exp > currentTime;
// };

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    window.localStorage.removeItem('accessToken');
    // delete axios.defaults.headers.common.Authorization;
  }
};

export {/* isValidToken */ setSession, verify, sign};
