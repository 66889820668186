import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  personalSettings: {
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  passwordSettings: {
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
};

// profile Settings slice
const profileSettingsSlice = createSlice({
  name: 'profileSettings',
  initialState,
  reducers: {
    // update personal settings
    putPersonalSettingsStart: (state, action) => {
      state.personalSettings.update = initialState.personalSettings.update;
    },
    putPersonalSettingsPending: state => {
      state.personalSettings.update.pending = true;
    },
    putPersonalSettingsFail: (state, action) => {
      const {error} = action.payload;
      state.personalSettings.update = {
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putPersonalSettingsSuccess: (state, action) => {
      const {data} = action.payload;
      state.personalSettings.update = {
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutPersonalSettingsState: state => {
      state.personalSettings.update = initialState.personalSettings.update;
    },
    // update password settings
    putPasswordSettingsStart: (state, action) => {
      state.passwordSettings.update = initialState.passwordSettings.update;
    },
    putPasswordSettingsPending: state => {
      state.passwordSettings.update.pending = true;
    },
    putPasswordSettingsFail: (state, action) => {
      const {error} = action.payload;
      state.passwordSettings.update = {
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putPasswordSettingsSuccess: (state, action) => {
      const {data} = action.payload;
      state.passwordSettings.update = {
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutPasswordSettingsState: state => {
      state.passwordSettings.update = initialState.passwordSettings.update;
    },
  },
});

const {actions, reducer} = profileSettingsSlice;
export {actions, reducer};
