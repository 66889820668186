import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  pending: false,
  data: null,
  error: null,
  success: false,
  update: {
    pending: false,
    data: null,
    error: null,
    success: false,
  },
  create: {
    pending: false,
    data: null,
    error: null,
    success: false,
  },
};

// account Settings slice
const accSettingsSlice = createSlice({
  name: 'accSettings',
  initialState,
  reducers: {
    // accSettings edit page
    getAccSettingsStart: state => {
      state = initialState;
    },
    getAccSettingsPending: state => {
      state.pending = true;
    },
    getAccSettingsFail: (state, action) => {
      const {error} = action.payload;
      return {
        ...state,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getAccSettingsSuccess: (state, action) => {
      const {data} = action.payload;
      return {
        ...state,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    // new account settings
    postAccSettingsStart: (state, action) => {},
    postAccSettingsPending: state => {
      state.create = {
        ...state.create,
        pending: true,
      };
    },
    postAccSettingsFail: (state, action) => {
      const {error} = action.payload;
      state.create = {
        ...state.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postAccSettingsSuccess: (state, action) => {
      const {data} = action.payload;
      state.create = {
        ...state.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostAccSettingsState: state => {
      state.create = initialState.create;
    },
    // new photo

    // update accSettings
    putAccSettingsStart: (state, action) => {
      state.update = initialState.update;
    },
    putAccSettingsPending: state => {
      state.update = {
        ...state.update,
        pending: true,
      };
    },
    putAccSettingsFail: (state, action) => {
      const {error} = action.payload;
      state.update = {
        ...state.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putAccSettingsSuccess: (state, action) => {
      const {data} = action.payload;
      state.update = {
        ...state.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutAccSettingsState: state => {
      state.update = initialState.update;
    },
  },
});

const {actions, reducer} = accSettingsSlice;
export {actions, reducer};
