import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

const apiCall = (endPoint, method, json = undefined) => {
  return axiosInstance({
    method,
    url: endPoint,
    data: json,
  })
    .then(response => ({response}))
    .catch(error => {
      return {error: JSON.parse(JSON.stringify(error))};
    });
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export {apiCall};
export default axiosInstance;
