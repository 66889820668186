import {ForkEffect, spawn} from 'redux-saga/effects';

import {authSaga} from 'store/auth/saga';
import {registerSaga} from 'store/register/saga';
import {templateSaga} from 'store/template/saga';
import {companySaga} from 'store/company/saga';
import {locationSaga} from 'store/location/saga';
import {inspectionSaga} from 'store/inspection/saga';
import {reportSaga} from 'store/report/saga';
import {actionSaga} from 'store/action/saga';
import {userSaga} from 'store/user/saga';
import {roleSaga} from 'store/role/saga';
import {accSettingsSaga} from 'store/accountSettings/saga';
import {profileSettingsSaga} from 'store/profileSettings/saga';
import {subscriptionSaga} from 'store/subscription/saga';
import {permissonSaga} from 'store/permission/saga';

export function* rootSaga(): Generator<ForkEffect<void>, void, unknown> {
  yield spawn(authSaga);
  yield spawn(registerSaga);
  yield spawn(templateSaga);
  yield spawn(companySaga);
  yield spawn(locationSaga);
  yield spawn(inspectionSaga);
  yield spawn(reportSaga);
  yield spawn(actionSaga);
  yield spawn(userSaga);
  yield spawn(roleSaga);
  yield spawn(accSettingsSaga);
  yield spawn(profileSettingsSaga);
  yield spawn(subscriptionSaga);
  yield spawn(permissonSaga);
}
