import React from 'react';
// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// components
// import SvgIconStyle from '../../components/SvgIconStyle';
import {
  HomeIcon,
  BallotIcon,
  AssignmentIcon,
  AssignmentTurnedInIcon,
  PeopleIcon,
  AdminPanelSettings,
  HomeWorkIcon,
  SettingsIcon,
  Apartment,
  Room,
} from '../../icons';
import {RolesEnum} from 'guards/RolePermissionGuard';

// ----------------------------------------------------------------------

// don't need svg for now, maybe later when designer give us svg files.
// const getIcon = name => (
//   <SvgIconStyle
//     src={`/static/icons/navbar/${name}.svg`}
//     sx={{width: '100%', height: '100%'}}
//   />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   kanban: getIcon('ic_kanban'),
//   banking: getIcon('ic_banking'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   booking: getIcon('ic_booking'),
// };

const sidebarConfig = [
  {
    subheader: 'dashboard',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.home,
        icon: <HomeIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'templates',
        path: PATH_DASHBOARD.templates.root,
        icon: <BallotIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'inspections',
        path: PATH_DASHBOARD.inspections.root,
        icon: <AssignmentIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'actions',
        path: PATH_DASHBOARD.actions.root,
        icon: <AssignmentTurnedInIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'companies',
        path: PATH_DASHBOARD.companies.root,
        icon: <Apartment fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'locations',
        path: PATH_DASHBOARD.locations.root,
        icon: <Room fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.users.root,
        icon: <PeopleIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin],
      },
      {
        title: 'roles & permissions',
        path: PATH_DASHBOARD.roles.root,
        icon: <AdminPanelSettings fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin],
      },
      // {
      //   title: 'settings',
      //   path: PATH_DASHBOARD.settings,
      //   icon: <SettingsIcon fontSize="small" />,
      //   disable: false,
      // },
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: <SettingsIcon fontSize="small" />,
        disable: false,
        roles: [RolesEnum.Admin, RolesEnum.User],
        children: [
          {
            title: 'account',
            path: PATH_DASHBOARD.settings.account,
            disable: false,
            roles: [RolesEnum.Admin],
          },
          {
            title: 'profile',
            path: PATH_DASHBOARD.settings.profile,
            disable: false,
            roles: [RolesEnum.Admin, RolesEnum.User],
          },
          {
            title: 'subscription',
            path: PATH_DASHBOARD.settings.subscription,
            roles: [RolesEnum.Admin],
            disable: false,
          },
        ],
      },
    ],
  },
];

export default sidebarConfig;
