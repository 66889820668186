import React from 'react';
import {useLocation} from 'react-router-dom';
// material
import {useTheme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {ReactComponent as LogoBlue} from '../assets/SIR_logo_blue.svg';
import {ReactComponent as LogoWhite} from '../assets/SIR_logo_white.svg';

// ----------------------------------------------------------------------

type LogoPropType = {
  sx?: {[x: string]: any};
};

const LogoLoadingScreen: React.FC<LogoPropType> = ({sx}): JSX.Element => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{width: 80, height: 80, display: 'flex', ...sx}}>
      <LogoBlue />
    </Box>
  );
};

export default LogoLoadingScreen;
