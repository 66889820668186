// ----------------------------------------------------------------------

export const varHover = {
  hover: {scale: 1.1},
};

export const varSmallClick = {
  hover: {scale: 1.04},
  tap: {scale: 0.96},
};

export const varMediumClick = {
  hover: {scale: 1.1},
  tap: {scale: 0.9},
};
