// routes
import Router from './routes';

import React from 'react';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
import {RootStateOrAny, useSelector} from 'react-redux';

import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import BackDropSpinner from './components/BackDropSpinner';
import DialogNewAppVersion from 'components/DialogNewAppVersion';

// wire all styles of the app
// remove this later on, we don't need it here anymore because we are using material theme
// import 'sass/main.scss';

const App: React.FC = () => {
  const {isInitialized} = useAuth();

  // for later when we dig in offline mode
  // const isServiceWorkerInitalized = useSelector(
  //   ({general}: RootStateOrAny) => general.general.serviceWorkerInitialized
  // );

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            {/* This is to allow user to have action of 'refreshing the page' */}
            <DialogNewAppVersion />
            <ScrollToTop />
            <BackDropSpinner />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
};

export default App;
