import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {accSettingsApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// accsettings edit page - post / update account settings
function* postAccSettingsSaga(action) {
  const {postObj} = action.payload;
  const endPoint = accSettingsApi;
  yield put(actions.postAccSettingsPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'post', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postAccSettingsSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postAccSettingsFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postAccSettingsFail({error: {message: 'something went wrong'}}));
  }
}

// accSettings edit page
function* getAccSettingsSaga() {
  const endPoint = accSettingsApi;

  yield put(actions.getAccSettingsPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.getAccSettingsSuccess({data: response.data}));
    } else {
      yield put(actions.getAccSettingsFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getAccSettingsFail({error: {message: 'something went wrong'}}));
  }
}

// accSettings edit page - update accSettings by id
function* putAccSettingsSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = accSettingsApi + '/' + id;

  yield put(actions.putAccSettingsPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'put', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putAccSettingsSuccess({data: response.data}));
    } else {
      yield put(actions.putAccSettingsFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.putAccSettingsFail({error: {message: 'something went wrong'}}));
  }
}

export function* accSettingsSaga(): Generator<ForkEffect<never>, void, unknown> {
  // accSettings edit page - post / update accSettings by id
  yield takeEvery(actions.postAccSettingsStart.type, postAccSettingsSaga);
  // accSettings edit page
  yield takeLatest(actions.getAccSettingsStart.type, getAccSettingsSaga);
  // accSettings edit page - post / update accSettings by id
  yield takeEvery(actions.putAccSettingsStart.type, putAccSettingsSaga);
}
