import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// import {AuthStateType, User} from './types';

const initialState = {
  template: {
    id: null,
    uid: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    childrenCount: 0,
    childrenSaved: [],
    isChildrenConnected: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    containersPosition: {
      pending: false,
      error: null,
      success: false,
    },
    sectionsPosition: {
      pending: false,
      error: null,
      success: false,
    },
    fieldsPosition: {
      pending: false,
      error: null,
      success: false,
    },
    containers: {},
    sections: {},
    fields: {},
    requests: {
      pendingCount: 0,
      failCount: 0,
      successCount: 0,
    },
    ui: {
      isHideFooter: false,
    },
  },
  templateList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    delete: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
  },
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    // new template
    postTemplateStart: (state, action) => {
      const {childrenCount, uid} = action.payload;
      state.template = {
        ...state.template,
        id: null,
        uid: uid,
        pending: false,
        data: null,
        error: null,
        success: false,
        childrenCount: childrenCount,
        childrenSaved: [],
        isChildrenConnected: false,
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
        delete: {
          pending: false,
          error: null,
          success: false,
        },
      };
    },
    postTemplatePending: state => {
      state.template = {
        ...state.template,
        pending: true,
      };
    },
    postTemplateFail: (state, action) => {
      const {error} = action.payload;
      state.template = {
        ...state.template,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postTemplateSuccess: (state, action) => {
      const {data} = action.payload;
      state.template = {
        ...state.template,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    // update template
    putTemplateStart: (state, action) => {
      state.template = {
        ...state.template,
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putTemplatePending: state => {
      state.template.update = {
        ...state.template.update,
        pending: true,
      };
    },
    putTemplateFail: (state, action) => {
      const {error} = action.payload;
      state.template.update = {
        ...state.template.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putTemplateSuccess: (state, action) => {
      const {data} = action.payload;
      state.template.update = {
        ...state.template.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
      state.template.data = data.data;
    },
    updateTemplateIsChildrenConnected: (state, action) => {
      state.template = {
        ...state.template,
        isChildrenConnected: action.payload,
      };
    },
    // new container
    postContainerStart: (state, action) => {
      const {uid, puid, childrenCount} = action.payload;
      state.template.containers[uid] = {
        ...state.template.containers[uid],
        id: null,
        uid: uid,
        puid: puid,
        pending: false,
        error: null,
        success: false,
        childrenCount: childrenCount,
        childrenSaved: [],
        isChildrenConnected: false,
      };
    },
    postContainerPending: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid].pending = true;
    },
    postContainerFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.containers[uid] = {
        ...state.template.containers[uid],
        data: null,
        pending: false,
        error: error,
        success: false,
      };
    },
    postContainerSuccess: (state, action) => {
      const {uid, order, data} = action.payload;
      state.template.containers[uid] = {
        ...state.template.containers[uid],
        id: data.data.id,
        data: data.data,
        pending: false,
        error: null,
        success: true,
      };
    },
    afterPostContainerSuccess: (state, action) => {
      // this action only happens on first load of new template form.
      // or new parent added to the form.
      const {order, data} = action.payload;
      // update template object to have the correct saved container.
      state.template.childrenSaved = [
        ...state.template.childrenSaved,
        {
          order: `${order}`,
          id: data.data.id,
        },
      ];
    },
    // update container
    putContainerStart: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid] = {
        ...state.template.containers[uid],
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putContainerPending: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid].update = {
        ...state.template.containers[uid].update,
        pending: true,
      };
    },
    putContainerFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.containers[uid].update = {
        ...state.template.containers[uid].update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putContainerSuccess: (state, action) => {
      const {uid, data} = action.payload;
      state.template.containers[uid].update = {
        ...state.template.containers[uid].update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };

      state.template.containers[uid].data = data.data;
    },
    updateContainerIsChildrenConnected: (state, action) => {
      const {uid, value} = action.payload;
      state.template.containers[uid] = {
        ...state.template.containers[uid],
        isChildrenConnected: value,
      };
    },
    // delete container
    deleteContainerStart: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid].delete = {
        ...state.template.containers[uid].delete,
        pending: false,
        error: null,
        success: false,
      };
    },
    deleteContainerPending: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid].delete = {
        ...state.template.containers[uid].delete,
        pending: true,
      };
    },
    deleteContainerFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.containers[uid].delete = {
        ...state.template.containers[uid].delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteContainerSuccess: (state, action) => {
      const {uid} = action.payload;
      state.template.containers[uid].delete = {
        ...state.template.containers[uid].delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // update container position
    putContainersPositionStart: (state, action) => {},
    putContainersPositionPending: state => {
      state.template.containersPosition = {
        ...state.template.containersPosition,
        pending: true,
        error: null,
        success: false,
      };
    },
    putContainersPositionFail: (state, action) => {
      const {error} = action.payload;
      state.template.containersPosition = {
        ...state.template.containersPosition,
        pending: false,
        error: error,
        success: false,
      };
    },
    putContainersPositionSuccess: state => {
      state.template.containersPosition = {
        ...state.template.containersPosition,
        pending: false,
        error: null,
        success: true,
      };
    },
    // new section
    postSectionStart: (state, action) => {
      const {uid, puid, childrenCount} = action.payload;
      state.template.sections[uid] = {
        ...state.template.sections[uid],
        id: null,
        uid: uid,
        puid: puid,
        pending: false,
        error: null,
        success: false,
        childrenCount: childrenCount,
        childrenSaved: [],
        isChildrenConnected: false,
      };
    },
    postSectionPending: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid].pending = true;
    },
    postSectionFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.sections[uid] = {
        ...state.template.sections[uid],
        data: null,
        pending: false,
        error: error,
        success: false,
      };
    },
    postSectionSuccess: (state, action) => {
      const {uid, puid, order, data} = action.payload;
      state.template.sections[uid] = {
        ...state.template.sections[uid],
        id: data.data.id,
        data: data.data,
        pending: false,
        error: null,
        success: true,
      };
    },
    afterPostSectionSuccess: (state, action) => {
      // this action only happens on first load of new template form.
      // or new parent added to the form.
      const {puid, order, data} = action.payload;
      // update section object to have the correct saved field.
      state.template.containers[puid].childrenSaved = [
        ...state.template.containers[puid].childrenSaved,
        {
          order: `${order}`,
          id: data.data.id,
        },
      ];
    },
    // update section
    putSectionStart: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid] = {
        ...state.template.sections[uid],
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putSectionPending: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid].update = {
        ...state.template.sections[uid].update,
        pending: true,
      };
    },
    putSectionFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.sections[uid].update = {
        ...state.template.sections[uid].update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putSectionSuccess: (state, action) => {
      const {uid, data} = action.payload;
      state.template.sections[uid].update = {
        ...state.template.sections[uid].update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
      state.template.sections[uid].data = data.data;
    },
    updateSectionIsChildrenConnected: (state, action) => {
      const {uid, value} = action.payload;
      state.template.sections[uid] = {
        ...state.template.sections[uid],
        isChildrenConnected: value,
      };
    },
    // update section position
    putSectionsPositionStart: (state, action) => {},
    putSectionsPositionPending: state => {
      state.template.sectionsPosition = {
        ...state.template.sectionsPosition,
        pending: true,
        error: null,
        success: false,
      };
    },
    putSectionsPositionFail: (state, action) => {
      const {error} = action.payload;
      state.template.sectionsPosition = {
        ...state.template.sectionsPosition,
        pending: false,
        error: error,
        success: false,
      };
    },
    putSectionsPositionSuccess: state => {
      state.template.sectionsPosition = {
        ...state.template.sectionsPosition,
        pending: false,
        error: null,
        success: true,
      };
    },
    // delete section
    deleteSectionStart: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid].delete = {
        ...state.template.sections[uid].delete,
        pending: false,
        error: null,
        success: false,
      };
    },
    deleteSectionPending: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid].delete = {
        ...state.template.sections[uid].delete,
        pending: true,
      };
    },
    deleteSectionFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.sections[uid].delete = {
        ...state.template.sections[uid].delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteSectionSuccess: (state, action) => {
      const {uid} = action.payload;
      state.template.sections[uid].delete = {
        ...state.template.sections[uid].delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // new field
    postFieldStart: (state, action) => {
      const {uid, puid} = action.payload;
      state.template.fields[uid] = {
        ...state.template.fields[uid],
        id: null,
        uid: uid,
        puid: puid,
        pending: false,
        error: null,
        success: false,
      };
    },
    postFieldPending: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid].pending = true;
    },
    postFieldFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.fields[uid] = {
        ...state.template.fields[uid],
        data: null,
        pending: false,
        error: error,
        success: false,
      };
    },
    postFieldSuccess: (state, action) => {
      const {uid, puid, order, data} = action.payload;
      state.template.fields[uid] = {
        ...state.template.fields[uid],
        id: data.data.id,
        data: data.data,
        pending: false,
        error: null,
        success: true,
      };
    },
    afterPostFieldSuccess: (state, action) => {
      // this action only happens on first load of new template form.
      // or new parent was added to the form.
      const {puid, order, data} = action.payload;
      // update section object to have the correct saved field.
      state.template.sections[puid].childrenSaved = [
        ...state.template.sections[puid].childrenSaved,
        {
          order: `${order}`,
          id: data.data.id,
        },
      ];
    },
    // update field
    putFieldStart: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid] = {
        ...state.template.fields[uid],
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putFieldPending: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid].update = {
        ...state.template.fields[uid].update,
        pending: true,
      };
    },
    putFieldFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.fields[uid].update = {
        ...state.template.fields[uid].update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putFieldSuccess: (state, action) => {
      const {uid, data} = action.payload;
      state.template.fields[uid].update = {
        ...state.template.fields[uid].update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
      state.template.fields[uid].data = data.data;
    },
    // update field position
    putFieldsPositionStart: (state, action) => {},
    putFieldsPositionPending: state => {
      state.template.fieldsPosition = {
        ...state.template.fieldsPosition,
        pending: true,
        error: null,
        success: false,
      };
    },
    putFieldsPositionFail: (state, action) => {
      const {error} = action.payload;
      state.template.fieldsPosition = {
        ...state.template.fieldsPosition,
        pending: false,
        error: error,
        success: false,
      };
    },
    putFieldsPositionSuccess: state => {
      state.template.fieldsPosition = {
        ...state.template.fieldsPosition,
        pending: false,
        error: null,
        success: true,
      };
    },
    // delete field
    deleteFieldStart: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid].delete = {
        ...state.template.fields[uid].delete,
        pending: false,
        error: null,
        success: false,
      };
    },
    deleteFieldPending: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid].delete = {
        ...state.template.fields[uid].delete,
        pending: true,
      };
    },
    deleteFieldFail: (state, action) => {
      const {uid, error} = action.payload;
      state.template.fields[uid].delete = {
        ...state.template.fields[uid].delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteFieldSuccess: (state, action) => {
      const {uid} = action.payload;
      state.template.fields[uid].delete = {
        ...state.template.fields[uid].delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // reset template single page redux state
    resetTemplateState: state => {
      state.template = initialState.template;
    },
    cancelAllTemplateRequests: () => {},
    // template listing page
    getAllTemplatesStart: state => {
      state.templateList = {
        ...state.templateList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
        delete: {},
      };
    },
    getAllTemplatesPending: state => {
      state.templateList = {
        ...state.templateList,
        pending: true,
      };
    },
    getAllTemplatesFail: (state, action) => {
      const {error} = action.payload;
      state.templateList = {
        ...state.templateList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllTemplatesSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.templateList = {
        ...state.templateList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetTemplateListState: state => {
      state.templateList = initialState.templateList;
    },
    deleteTemplateStart: (state, action) => {
      const {id} = action.payload;
      state.templateList.delete = {
        ...state.templateList.delete,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    deleteTemplatePending: state => {
      state.templateList.delete = {
        ...state.templateList.delete,
        pending: true,
      };
    },
    deleteTemplateFail: (state, action) => {
      const {error} = action.payload;
      state.templateList.delete = {
        ...state.templateList.delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteTemplateSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.templateList.items};
      delete updatedItems[id];

      state.templateList = {
        ...state.templateList,
        items: updatedItems,
      };

      state.templateList.delete = {
        ...state.templateList.delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    resetDeleteTemplate: state => {
      state.templateList.delete = {
        ...initialState.templateList.delete,
      };
    },
    // template edit page
    getTemplateByIdStart: (state, action) => {
      state.template = {
        ...state.template,
        id: null,
        uid: null,
        pending: false,
        data: null,
        error: null,
        success: false,
        isChildrenConnected: true,
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
        containers: {},
        sections: {},
        fields: {},
      };
    },
    getTemplateByIdPending: state => {
      state.template.pending = true;
    },
    getTemplateByIdFail: (state, action) => {
      const {error} = action.payload;
      state.template = {
        ...state.template,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getTemplateByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.template = {
        ...state.template,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    // push data to redux state after getting template data
    pushTemplateUidAfterLoad: (state, action) => {
      const {uid} = action.payload;
      state.template.uid = uid;
    },
    pushContainerDataAfterLoad: (state, action) => {
      const {data} = action.payload;
      state.template.containers[data.uid] = {
        ...state.template.containers[data.uid],
        id: data.data.id,
        uid: data.uid,
        puid: data.puid,
        data: data.data,
        pending: false,
        error: null,
        success: false,
        // important
        isChildrenConnected: true,
      };
    },
    pushSectionDataAfterLoad: (state, action) => {
      const {data} = action.payload;
      state.template.sections[data.uid] = {
        ...state.template.sections[data.uid],
        id: data.data.id,
        uid: data.uid,
        puid: data.puid,
        data: data.data,
        pending: false,
        error: null,
        success: false,
        // important
        isChildrenConnected: true,
      };
    },
    pushFieldDataAfterLoad: (state, action) => {
      const {data} = action.payload;
      state.template.fields[data.uid] = {
        ...state.template.fields[data.uid],
        id: data.data.id,
        uid: data.uid,
        puid: data.puid,
        data: data.data,
        pending: false,
        error: null,
        success: false,
      };
    },
    // keep track of requests
    addPendingTemplateRequest: state => {
      state.template.requests = {
        ...state.template.requests,
        pendingCount: state.template.requests.pendingCount + 1,
      };
    },
    removePendingTemplateRequest: state => {
      state.template.requests = {
        ...state.template.requests,
        pendingCount: state.template.requests.pendingCount - 1,
      };
    },
    addFailTemplateRequest: state => {
      state.template.requests = {
        ...state.template.requests,
        failCount: state.template.requests.failCount + 1,
        pendingCount: state.template.requests.pendingCount - 1,
      };
    },
    addSuccessTemplateRequest: state => {
      state.template.requests = {
        ...state.template.requests,
        successCount: state.template.requests.successCount + 1,
        pendingCount: state.template.requests.pendingCount - 1,
      };
    },

    // UI
    setUiHideTemplateFooter: (state, action) => {
      const {isHideFooter} = action.payload;
      state.template.ui.isHideFooter = isHideFooter;
    },
  },
});

const {actions, reducer} = templateSlice;
export {actions, reducer};
