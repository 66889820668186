import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  permission: {
    permissionList: null,
    success: false,
    error: null,
    pending: false,
  },
};

//permission slice

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    getAllPermissionStart: (state, action) => {
      state.permission = {
        ...state.permission,
        permissionList: null,
        pending: false,
        error: null,
        success: false,
      };
    },
    getAllPermissionPending: state => {
      state.permission = {
        ...state.permission,
        pending: true,
      };
    },
    getAllPermissionFail: (state, action) => {
      const {error} = action.payload;
      state.permission = {
        ...state.permission,
        permissionList: null,
        success: false,
        pending: false,
        error,
      };
    },
    getAllPermissionSuccess: (state, action) => {
      const {data} = action.payload;
      state.permission = {
        ...state.permission,
        permissionList: data.data,
        success: true,
        pending: false,
        error: null,
      };
    },
  },
});

const {actions, reducer} = permissionSlice;
export {actions, reducer};
