import React from 'react';
import {Container, Alert, AlertTitle} from '@material-ui/core';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

export enum RolesEnum {
  Admin = 'admin',
  User = 'user',
}

// TODO:
export enum PermissionsEnum {}
interface RolePermissionGuardProps {
  roles?: string[];
  // TODO: think of how to shape permission object.
  permissions?: any;
}

const RolePermissionGuard: React.FC<RolePermissionGuardProps> = ({
  roles,
  permissions,
  children,
}) => {
  const {role, permission} = useAuth();

  if (!role || (roles && !roles.includes(role))) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  if (!permission || (permissions && !permissions.includes(permission))) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
};

export default RolePermissionGuard;
