// material-ui
import HomeIcon from '@material-ui/icons/Home';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PeopleIcon from '@material-ui/icons/People';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaymentIcon from '@material-ui/icons/Payment';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Apartment from '@material-ui/icons/Apartment';
import Room from '@material-ui/icons/Room';
import InsertInvitation from '@material-ui/icons/InsertInvitation';
import HelpOutline from '@material-ui/icons/HelpOutline';
import SaveRounded from '@material-ui/icons/SaveRounded';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Share from '@material-ui/icons/Share';
import Assignment from '@material-ui/icons/Assignment';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import AdminPanelSettings from '@material-ui/icons/AdminPanelSettings';
import InventoryIcon from '@material-ui/icons/Inventory';
// eva
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editOutline from '@iconify/icons-eva/edit-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import trashFill from '@iconify/icons-eva/trash-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import alertCircleOutline from '@iconify/icons-eva/alert-circle-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';

// ant-design
import homeOutline from '@iconify/icons-ant-design/home-outline';
import userOutline from '@iconify/icons-ant-design/user-outline';
import cardOutline from '@iconify/icons-ant-design/credit-card-outline';
import teamOutline from '@iconify/icons-ant-design/team-outline';
import settingOutline from '@iconify/icons-ant-design/setting-outline';
import checkOutline from '@iconify/icons-ant-design/check-outline';
import closeOutline from '@iconify/icons-ant-design/close-outline';
import dragline from '@iconify/icons-ant-design/drag-outline';

// unicons
import editAlt from '@iconify/icons-uil/edit-alt';
import eye from '@iconify/icons-uil/eye';
import trash from '@iconify/icons-uil/trash';
import angleRightB from '@iconify/icons-uil/angle-right-b';

// entypo
import ticket from '@iconify/icons-entypo/ticket';

// icons-ic
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';

export {
  // material ui
  HomeIcon,
  BallotIcon,
  AssignmentIcon,
  AssignmentTurnedInIcon,
  PeopleIcon,
  HomeWorkIcon,
  SettingsIcon,
  AccountCircleIcon,
  PaymentIcon,
  DragIndicatorIcon,
  ControlPointIcon,
  Apartment,
  Room,
  InsertInvitation,
  HelpOutline,
  SaveRounded,
  PlaylistAddCheck,
  CloudDownload,
  Share,
  Assignment,
  ChevronLeft,
  ChevronRight,
  AdminPanelSettings,
  InventoryIcon,
  // eva
  trash2Outline,
  editOutline,
  editFill,
  closeFill,
  checkmarkFill,
  trashFill,
  arrowIosDownwardFill,
  arrowIosForwardFill,
  arrowIosBackFill,
  checkmarkCircle2Outline,
  checkmarkCircle2Fill,
  alertCircleOutline,
  plusFill,
  moreVerticalFill,
  moreHorizontalFill,
  closeCircleOutline,
  // ant design
  homeOutline,
  userOutline,
  cardOutline,
  teamOutline,
  settingOutline,
  checkOutline,
  closeOutline,
  dragline,
  // unicons
  editAlt,
  eye,
  trash,
  angleRightB,
  // entypo
  ticket,
  // icons-c
  roundAccountBox,
  roundVpnKey,
};
