import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@material-ui/core/styles';
import {Box, Card, Stack, Link, Container, Typography} from '@material-ui/core';
// routes
import {PATH_AUTH} from '../../routes/paths';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import {MHidden} from '../../components/@material-extend';
import {LoginForm} from '../../components/authentication/login';

import JpgLogin from '../../assets/SIR_login_02.jpg';

// ----------------------------------------------------------------------
type StyledPageProps = {title?: string};

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
})) as React.ComponentType<StyledPageProps>;

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Login: React.FC = () => {
  return (
    <RootStyle title="Login | Site Inspection Reporting">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          Get started
        </Link>
      </AuthLayout>
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            src={JpgLogin}
            alt="loading..."
            style={{
              minHeight: '100%',
              maxWidth: 'none',
              position: 'absolute',
              overflow: 'hidden',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{mb: 5}}>
            <Box sx={{flexGrow: 1}}>
              <Typography variant="h4" gutterBottom>
                Login to Site Inspection Reporting
              </Typography>
              {/* <Typography sx={{color: 'text.secondary'}}>
                Enter your details below.
              </Typography> */}
            </Box>
          </Stack>

          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{mt: 3, fontWeight: 700}}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
