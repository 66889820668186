import React from 'react';
// hooks
import useAuth from '../hooks/useAuth';
//
import {MAvatar} from './@material-extend';
import {ReactComponent as UserAvatar} from '../assets/SIR_avatar.svg';

// ----------------------------------------------------------------------
type Props = {
  sx?: {[x: string]: string | number};
};

const MyAvatar: React.FC<Props> = ({...other}) => {
  const {user} = useAuth();
  if (user !== null) {
    return (
      <MAvatar
        src={user.photoURL}
        alt={user.displayName}
        // color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
        color={'primary'}
        {...other}
      >
        {/* {createAvatar(user.displayName).name} */}
        <UserAvatar style={{margin: '10px', width: '20px'}} />
      </MAvatar>
    );
  } else {
    return <></>;
  }
};

export default MyAvatar;
