import React, {Suspense, lazy} from 'react';
import {Navigate, useRoutes, useLocation} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RolePermissionGuard from 'guards/RolePermissionGuard';
// enum
import {RolesEnum} from '../guards/RolePermissionGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const Loadable = Component => props => {
  const {pathname} = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Router() {
  return useRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'user/invite',
      element: (
        <GuestGuard>
          <RegisterUser />
        </GuestGuard>
      ),
    },
    {
      path: 'register-success',
      element: (
        <GuestGuard>
          <RegisterConfirmation />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },

    // {path: 'login-unprotected', element: <Login />},
    // {path: 'register-unprotected', element: <Register />},
    // {path: 'verify', element: <VerifyCode />},

    // Dashboard Route
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {path: '/', element: <Navigate to="/dashboard/home" replace />},
        {path: 'home', element: <GeneralApp />},
        {
          path: 'templates',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <TemplatesPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'item/:id',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <TemplatePage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <TemplateNewPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'inspections',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <InspectionsPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'actions',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <ActionsPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'item/:id',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <ActionPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'companies',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <CompaniesPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <CompanyNewPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'item/:id',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <CompanyPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'locations',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <LocationsPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <LocationNewPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'item/:id',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <LocationPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'users',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <UsersPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'invite',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <UserInvitePage />
                </RolePermissionGuard>
              ),
            },
            // {
            //   path: 'item/:id',
            //   element: (
            //     <RolePermissionGuard roles={[RolesEnum.Admin]}>
            //       <UserPage />
            //     </RolePermissionGuard>
            //   ),
            // },
          ],
        },
        {
          path: 'roles',
          children: [
            {
              path: '/',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <RolesPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <RoleNewPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'item/:id',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <RolePage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
        // {path: 'settings', element: <SettingsPage />},
        {
          path: 'settings',
          children: [
            {path: '/', element: <Navigate to="/dashboard/settings/profile" replace />},
            {
              path: 'account',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <AccountSettingsPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'subscription',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin]}>
                  <SubscriptionSettingsPage />
                </RolePermissionGuard>
              ),
            },
            {
              path: 'profile',
              element: (
                <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
                  <ProfileSettingsPage />
                </RolePermissionGuard>
              ),
            },
          ],
        },
      ],
    },

    // Inspection route
    {
      path: 'dashboard/inspections/item/:id',
      element: (
        <AuthGuard>
          <RolePermissionGuard roles={[RolesEnum.Admin, RolesEnum.User]}>
            <InspectionPage />
          </RolePermissionGuard>
        </AuthGuard>
      ),
    },

    // Report route (Public for now)
    {
      path: 'inspection/report/:id',
      element: <ReportPage />,
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {path: 'password-updated', element: <PasswordUpdatedPage />},
        {path: 'coming-soon', element: <ComingSoon />},
        {path: 'maintenance', element: <Maintenance />},
        {path: '500', element: <Page500 />},
        {path: '404', element: <NotFound />},
        {path: '*', element: <Navigate to="/404" replace />},
      ],
    },
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     {path: '/', element: <LandingPage />},
    //     {path: 'about-us', element: <About />},
    //     {path: 'faqs', element: <Faqs />},
    //   ],
    // },
    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterUser = Loadable(lazy(() => import('../pages/authentication/RegisterUser')));
const RegisterConfirmation = Loadable(
  lazy(() => import('../pages/authentication/RegisterConfirmationPage'))
);
const ForgotPassword = Loadable(
  lazy(() => import('../pages/authentication/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword'))
);
const VerifyCode = Loadable(
  lazy(() => import('../minimal/pages/authentication/VerifyCode'))
);
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const TemplatesPage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplatesPage'))
);
const TemplateNewPage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplateNewPage'))
);
const TemplatePage = Loadable(
  lazy(() => import('../pages/dashboard/template/TemplatePage'))
);
const InspectionsPage = Loadable(
  lazy(() => import('../pages/dashboard/inspection/InspectionsPage'))
);
const InspectionPage = Loadable(
  lazy(() => import('../pages/dashboard/inspection/InspectionPage'))
);
const ActionsPage = Loadable(lazy(() => import('../pages/dashboard/action/ActionsPage')));
const ActionPage = Loadable(lazy(() => import('../pages/dashboard/action/ActionPage')));
const UsersPage = Loadable(lazy(() => import('../pages/dashboard/user/UsersPage')));
// const UserPage = Loadable(lazy(() => import('../pages/dashboard/user/UserPage')));
const UserInvitePage = Loadable(
  lazy(() => import('../pages/dashboard/user/UserInvitePage'))
);
const RolesPage = Loadable(lazy(() => import('../pages/dashboard/role/RolesPage')));
const RolePage = Loadable(lazy(() => import('../pages/dashboard/role/RolePage')));
const RoleNewPage = Loadable(lazy(() => import('../pages/dashboard/role/RoleNewPage')));
const CompaniesPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompaniesPage'))
);
const CompanyNewPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyNewPage'))
);
const CompanyPage = Loadable(
  lazy(() => import('../pages/dashboard/company/CompanyPage'))
);
const LocationsPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationsPage'))
);
const LocationNewPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationNewPage'))
);
const LocationPage = Loadable(
  lazy(() => import('../pages/dashboard/location/LocationPage'))
);
const AccountSettingsPage = Loadable(
  lazy(() => import('../pages/dashboard/settings/AccountSettingsPage'))
);
const ProfileSettingsPage = Loadable(
  lazy(() => import('../pages/dashboard/settings/ProfileSettingsPage'))
);
const PasswordUpdatedPage = Loadable(
  lazy(() => import('../pages/dashboard/settings/PasswordUpdatedPage'))
);
const SubscriptionSettingsPage = Loadable(
  lazy(() => import('../pages/dashboard/settings/SubscriptionSettingsPage'))
);
const ReportPage = Loadable(lazy(() => import('../pages/ReportPage')));

// Main

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// const LandingPage = Loadable(lazy(() => import('../minimal/pages/LandingPage')));
// const About = Loadable(lazy(() => import('../minimal/pages/About')));
// const Faqs = Loadable(lazy(() => import('../minimal/pages/Faqs')));
// const Pricing = Loadable(lazy(() => import('../minimal/pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../minimal/pages/Payment')));
