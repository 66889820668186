import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {userApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// user listing page
function* getAllUsersSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = userApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllUsersPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllUsersSuccess({data: response.data}));
    } else {
      yield put(actions.getAllUsersFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllUsersFail({error: {message: 'Something went wrong!'}}));
  }
}

// user listing page - archive user by id
function* archiveUserByIdSaga(action) {
  const {id} = action.payload;
  const endPoint = userApi + '/' + id;
  yield put(actions.archiveUserByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    if (response.data.status === 'Association constraint error') {
      yield put(actions.archiveUserByIdFail({error: {message: response.data.message}}));
    } else {
      yield put(actions.archiveUserByIdSuccess({id}));
    }
  } else if (error) {
    // TODO: update this when back-end has a better structure of response error.
    yield put(actions.archiveUserByIdFail({error: {message: 'something went wrong'}}));
  }
}

// user invite page
function* postUserInviteSaga(action) {
  const {postObj} = action.payload;
  const endPoint = userApi + '/send_invite';
  yield put(actions.postUserInvitePending());

  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.status && response.data.status.toLowerCase() === 'success') {
      yield put(actions.postUserInviteSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postUserInviteFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postUserInviteFail({error: {message: 'something went wrong'}}));
  }
}

// user invite verify page
function* postUserInviteVerifySaga(action) {
  const {invitation_token} = action.payload;
  console.log('Invitation Token ' + invitation_token);
  const endPoint = userApi + '/invite?invitation_token=' + invitation_token;
  yield put(actions.postUserInviteVerifyPending());

  const {response, error} = yield call(apiCall, endPoint, 'get');
  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.status && response.data.status.toLowerCase() === 'success') {
      yield put(actions.postUserInviteVerifySuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(
        actions.postUserInviteVerifyFail({error: {message: response.data.message}})
      );
    }
  } else if (error) {
    yield put(
      actions.postUserInviteVerifyFail({error: {message: response.data.message}})
    );
  }
}

// user edit page
// at the moment we don't have ability to create new user
// maybe in the future
// function* getUserByIdSaga(action) {
//   const {id, includeAll} = action.payload;
//   const endPoint = userApi + '/' + id + (includeAll ? '?include_all=true' : '');

//   yield put(actions.getUserByIdPending());
//   const {response, error} = yield call(apiCall, endPoint, 'get');

//   if (response && response.status === 200 && response.data) {
//     // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
//     if (response.data.data) {
//       yield put(actions.getUserByIdSuccess({data: response.data}));
//     } else {
//       yield put(actions.getUserByIdFail({error: {message: 'something went wrong'}}));
//     }
//   } else if (error) {
//     yield put(actions.getUserByIdFail({error: {message: 'something went wrong'}}));
//   }
// }

// user listing page - update user by id
function* putUserByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = userApi + '/' + id;

  yield put(actions.putUserByIdPending());

  const formData = new FormData();
  _.each(postObj, (value, key) => {
    formData.append(key, value);
  });

  const {response, error} = yield call(apiCall, endPoint, 'put', formData);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putUserByIdSuccess({data: response.data}));
    } else {
      yield put(actions.putUserByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.putUserByIdFail({error: {message: 'something went wrong'}}));
  }
}

export function* userSaga(): Generator<ForkEffect<never>, void, unknown> {
  // user listing page
  yield takeLatest(actions.getAllUsersStart.type, getAllUsersSaga);
  // user listing page - archive user by id
  yield takeEvery(actions.archiveUserByIdStart.type, archiveUserByIdSaga);
  // user listing page - update user by id
  yield takeEvery(actions.putUserByIdStart.type, putUserByIdSaga);
  // user invite page
  yield takeEvery(actions.postUserInviteStart.type, postUserInviteSaga);
  // user invite verify page
  yield takeEvery(actions.postUserInviteVerifyStart.type, postUserInviteVerifySaga);
  // user edit page
  // yield takeLatest(actions.getUserByIdStart.type, getUserByIdSaga);
}
