import React from 'react';
import {Icon} from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import {alpha, styled} from '@material-ui/core/styles';
import {Box, Stack, AppBar, Toolbar, IconButton} from '@material-ui/core';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import {useSelector, RootStateOrAny} from 'react-redux';
// components
import {MHidden} from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

interface ChildProps {
  onOpenSidebar: () => void;
}

const DashboardNavbar: React.FC<ChildProps> = ({onOpenSidebar}) => {
  const {isCollapse} = useCollapseDrawer();
  const pendingTemplateRequest = useSelector(
    ({template}: RootStateOrAny) => template.template.requests.pendingCount
  );

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: {lg: `calc(100% - ${COLLAPSE_WIDTH}px)`},
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            disabled={pendingTemplateRequest !== 0}
            onClick={onOpenSidebar}
            sx={{mr: 1, color: 'text.primary'}}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{flexGrow: 1}} />

        <Stack direction="row" alignItems="center" spacing={{xs: 0.5, sm: 1.5}}>
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};

export default DashboardNavbar;
