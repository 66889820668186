import React from 'react';
import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
// material
import {Box} from '@material-ui/core';

// ----------------------------------------------------------------------
type Props = {
  title: string;
  [x: string]: any;
};

const Page = forwardRef<any, Props>(({children, title = '', ...other}, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.displayName = 'Page';

export default Page;
