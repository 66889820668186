import React from 'react';

const IFrameTicketForm = ({onLoad}) => {
  return (
    <iframe
      // id="zsfeedbackFrame"
      width="100%"
      height="440"
      name="zsfeedbackFrame"
      scrolling="no"
      allowtransparency="false"
      frameBorder="0"
      border="0"
      onLoad={onLoad}
      src="https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=edbsn424eabe84fa939f72aaf80e1fcc1444696ea643bcda19cc6d2e1ff4c3b04f23e&xnQsjsdp=edbsn056134885752a1c3d0ad3d245c76b5d8&mode=showNewWidget&displayType=iframe"
    ></iframe>
  );
};

export default IFrameTicketForm;
