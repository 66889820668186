import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {roleApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// role listing page
function* getAllRolesSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = roleApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllRolesPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllRolesSuccess({data: response.data}));
    } else {
      yield put(actions.getAllRolesFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllRolesFail({error: {message: 'Something went wrong!'}}));
  }
}

// role listing page - delete role by id
function* deleteRoleByIdSaga(action) {
  const {id} = action.payload;
  const endPoint = roleApi + '/' + id;
  yield put(actions.deleteRoleByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    if (response.data.status === 'Association constraint error') {
      yield put(actions.deleteRoleByIdFail({error: {message: response.data.message}}));
    } else {
      yield put(actions.deleteRoleByIdSuccess({id}));
    }
  } else if (error) {
    // TODO: update this when back-end has a better structure of response error.
    yield put(actions.deleteRoleByIdFail({error: {message: 'something went wrong'}}));
  }
}

// role new page
function* postRoleSaga(action) {
  const {postObj} = action.payload;
  const endPoint = roleApi;
  yield put(actions.postRolePending());

  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postRoleSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postRoleFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postRoleFail({error: {message: 'something went wrong'}}));
  }
}

// role edit page
function* getRoleByIdSaga(action) {
  const {id, includeAll} = action.payload;
  const endPoint = roleApi + '/' + id + (includeAll ? '?include_all=true' : '');

  yield put(actions.getRoleByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.getRoleByIdSuccess({data: response.data}));
    } else {
      yield put(actions.getRoleByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.getRoleByIdFail({error: {message: 'something went wrong'}}));
  }
}

// role edit page - update role by id
function* putRoleByIdSaga(action) {
  const {postObj, id} = action.payload;
  console.log('POST obj ' + JSON.stringify(postObj));
  const endPoint = roleApi + '/' + id;

  yield put(actions.putRoleByIdPending());

  // const formData = new FormData();
  // _.each(postObj, (value, key) => {
  //   formData.append(key, value);
  // });

  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.putRoleByIdSuccess({data: response.data}));
    } else {
      yield put(actions.putRoleByIdFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.putRoleByIdFail({error: {message: 'something went wrong'}}));
  }
}

export function* roleSaga(): Generator<ForkEffect<never>, void, unknown> {
  // role listing page
  yield takeLatest(actions.getAllRolesStart.type, getAllRolesSaga);
  // role listing page - delete role by id
  yield takeEvery(actions.deleteRoleByIdStart.type, deleteRoleByIdSaga);
  // role new page
  yield takeEvery(actions.postRoleStart.type, postRoleSaga);
  // role edit page
  yield takeLatest(actions.getRoleByIdStart.type, getRoleByIdSaga);
  // role edit page - update role by id
  yield takeEvery(actions.putRoleByIdStart.type, putRoleByIdSaga);
}
