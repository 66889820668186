import React from 'react';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// material
import {IconButton} from '@material-ui/core';
//
import {ButtonAnimate} from '../animate';

// ----------------------------------------------------------------------
type Props = {
  [x: string]: any;
};

const MIconButton = forwardRef<any, Props>(({children, ...other}, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

MIconButton.propTypes = {
  children: PropTypes.node,
};

MIconButton.displayName = 'MIconButton';

export default MIconButton;
