import React from 'react';
import {Navigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_DASHBOARD} from '../routes/paths';

// ----------------------------------------------------------------------

const GuestGuard: React.FC = ({children}) => {
  const {isAuthenticated} = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
