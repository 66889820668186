import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  location: {
    id: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    create: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  locationList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    delete: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
  },
};

// location slice
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // new location
    postLocationStart: (state, action) => {},
    postLocationPending: state => {
      state.location.create = {
        ...state.location.create,
        pending: true,
      };
    },
    postLocationFail: (state, action) => {
      const {error} = action.payload;
      state.location.create = {
        ...state.location.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postLocationSuccess: (state, action) => {
      const {data} = action.payload;
      state.location.create = {
        ...state.location.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostLocationState: state => {
      state.location.create = initialState.location.create;
    },
    // update location
    putLocationByIdStart: (state, action) => {
      state.location.update = initialState.location.update;
    },
    putLocationByIdPending: state => {
      state.location.update = {
        ...state.location.update,
        pending: true,
      };
    },
    putLocationByIdFail: (state, action) => {
      const {error} = action.payload;
      state.location.update = {
        ...state.location.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putLocationByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.location.update = {
        ...state.location.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutLocationState: state => {
      state.location.update = initialState.location.update;
    },
    // delete location
    deleteLocationByIdStart: (state, action) => {
      const {id} = action.payload;
      state.locationList.delete = {
        ...state.locationList.delete,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    deleteLocationByIdPending: state => {
      state.locationList.delete = {
        ...state.locationList.delete,
        pending: true,
      };
    },
    deleteLocationByIdFail: (state, action) => {
      const {error} = action.payload;
      state.locationList.delete = {
        ...state.locationList.delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteLocationByIdSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.locationList.items};
      delete updatedItems[id];

      state.locationList = {
        ...state.locationList,
        items: updatedItems,
      };

      state.locationList.delete = {
        ...state.locationList.delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // location listing
    getAllLocationsStart: (state, action) => {
      state.locationList = {
        ...state.locationList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllLocationsPending: state => {
      state.locationList = {
        ...state.locationList,
        pending: true,
      };
    },
    getAllLocationsFail: (state, action) => {
      const {error} = action.payload;
      state.locationList = {
        ...state.locationList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllLocationsSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.locationList = {
        ...state.locationList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetLocationListState: state => {
      state.locationList = initialState.locationList;
    },
    // location edit page
    getLocationByIdStart: (state, action) => {
      state.location = initialState.location;
    },
    getLocationByIdPending: state => {
      state.location.pending = true;
    },
    getLocationByIdFail: (state, action) => {
      const {error} = action.payload;
      state.location = {
        ...state.location,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getLocationByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.location = {
        ...state.location,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
  },
});

const {actions, reducer} = locationSlice;
export {actions, reducer};
