import React from 'react';
import {forwardRef} from 'react';
// material
import {Avatar, useTheme} from '@material-ui/core';

// ----------------------------------------------------------------------
type Props = {
  src?: string;
  alt?: string;
  color?: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  sx: {
    [x: string]: string | number;
  };
  [x: string]: any;
};

const MAvatar = forwardRef<any, Props>(
  ({color = 'default', sx, children, ...other}, ref) => {
    const theme = useTheme();

    if (color === 'default') {
      return (
        <Avatar ref={ref} sx={sx} {...other}>
          {children}
        </Avatar>
      );
    }

    return (
      <Avatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Avatar>
    );
  }
);

MAvatar.displayName = 'MAvatar';
export default MAvatar;
