import {call, ForkEffect, put, takeEvery} from 'redux-saga/effects';
import {loginApi, currentUserApi, userApi, logoutApi} from 'configApi';
import {actions} from './slice';

// TODO: later on when have api, make sure to use our axios file
import {apiCall} from '../../utils/axios';
import {setSession} from '../../utils/jwt';

// TODO: assuming a lot of properties here. need to assert them before assigning.
// fragile check
const buildUserObject = (userData: any) => {
  return {
    id: userData.id,
    firstName: userData.attributes.first_name,
    lastName: userData.attributes.last_name,
    name: userData.attributes.first_name + ' ' + userData.attributes.last_name,
    displayName: userData.attributes.first_name,
    email: userData.attributes.email,
    phoneNumber: userData.attributes.phone_number,
    mobileNumber: userData.attributes.mobile_number,
    registered: userData.attributes.registered,
    company: userData.attributes.company,
    role: {
      id: userData.attributes.role.data.id,
      name: userData.attributes.role.data.attributes.name,
      description: userData.attributes.role.data.attributes.description,
      default: userData.attributes.role.data.attributes.default,
    },
    subscription: buildSubscriptionObj(userData.attributes.subscription.data),
    photoURL: '',
  };
};

// Build user object from subscription data returned from /auth/user
// TODO: assuming a lot of properties here. need to assert them before assigning.
// fragile check
const buildSubscriptionObj = (subscriptionData: any) => {
  return {
    id: subscriptionData ? subscriptionData.id : '',
    productName: subscriptionData ? subscriptionData.attributes.product_name : '',
    status: subscriptionData ? subscriptionData.attributes.status : '',
    amount: subscriptionData ? subscriptionData.attributes.amount : '',
    subscriptionNumber: subscriptionData
      ? subscriptionData.attributes.subscription_number
      : '',
    customerId: subscriptionData ? subscriptionData.attributes.customer_id : '',
    email: subscriptionData ? subscriptionData.attributes.email : '',
    createdTime: subscriptionData ? subscriptionData.attributes.created_time : '',
    nextBillingAt: subscriptionData ? subscriptionData.attributes.next_billing_at : '',
    activatedAt: subscriptionData ? subscriptionData.attributes.activated_at : '',
    currentTermStartsAt: subscriptionData
      ? subscriptionData.attributes.current_terms_starts_at
      : '',
    currentTermEndsAt: subscriptionData
      ? subscriptionData.attributes.current_term_ends_at
      : '',
    free: subscriptionData ? subscriptionData.attributes.free : '',
    relationship: {
      user: {
        id: subscriptionData ? subscriptionData.relationships.user.data.id : '',
      },
      plan: {
        id: subscriptionData ? subscriptionData.relationships.plan.data.id : '',
      },
    },
  };
};

function* getCurrentUserSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = currentUserApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getCurrentUserPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response?.data?.status && response.data.status.toLowerCase() === 'fail') {
    if (
      response.data.message &&
      response.data.message.toLowerCase() === 'invalid token.'
    ) {
      // technically the only time that invalid token can appear is when someone else logged in using the same account
      // so this will suffice for now
      // also, "Invalid token." string is set up in axios in front-end as well.
      // TODO: make sure it is the case.
      yield put(
        actions.getCurrentUserFail({
          error: 'Multiple logins are not supported in this release.',
        })
      );
    } else {
      yield put(actions.getCurrentUserFail({error: response.data.message}));
    }

    setSession(null);
  } else if (response?.data?.status && response.data.status.toLowerCase() === 'success') {
    // TODO: please put more check in cases any property is null / not returned !!
    yield put(actions.getCurrentUserSuccess(buildUserObject(response.data.data)));
  } else {
    setSession(null);
    yield put(actions.getCurrentUserFail({error: response.data.message}));
  }
}

function* postLoginSaga(action) {
  const endPoint = loginApi;

  yield put(actions.postLoginPending());
  const {email, password} = action.payload;
  const postObj: any = {
    field: {
      email: email,
      password: password,
    },
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.data && response.data.status === 'success') {
    const {jwt_token} = response.data.data;
    setSession(jwt_token);

    yield put(actions.postLoginSuccess());
    yield put(actions.getCurrentUserStart({includeAll: true}));
  } else {
    yield put(actions.postLoginFail({error: response.data.message}));
    setSession(null);
  }
}

function* postLogoutSaga() {
  const endPoint = logoutApi;
  yield put(actions.postLogoutPending());

  const {response, error} = yield call(apiCall, endPoint, 'post');
  if (response && response.data && response.data.status === 'success') {
    yield put(actions.postLogoutSuccess());
  } else {
    yield put(actions.postLogoutFail({error: response.data.message}));
  }
}

function* postForgotPasswordEmailSaga(action) {
  const endPoint = userApi + '/forgot_password';

  yield put(actions.postForgotPasswordEmailPending());
  const postObj: any = {
    field: action.payload,
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);
  if (response && response.data) {
    if (response.data.status === 'success') {
      yield put(actions.postForgotPasswordEmailSuccess());
    } else if (response.data.status === 'fail' && response.data.message) {
      yield put(actions.postForgotPasswordEmailFail({error: response.data.message}));
    } else {
      yield put(actions.postForgotPasswordEmailFail({error: 'Something went wrong'}));
    }
  } else {
    yield put(actions.postForgotPasswordEmailFail({error: 'Something went wrong'}));
  }
}

function* postResetPasswordSaga(action) {
  const endPoint = userApi + '/reset_password';

  yield put(actions.postResetPasswordPending());
  const postObj: any = {
    field: action.payload,
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.data) {
    if (response.data.status === 'success') {
      yield put(actions.postResetPasswordSuccess());
    } else if (response.data.status === 'fail' && response.data.message) {
      yield put(actions.postResetPasswordFail({error: response.data.message}));
    } else {
      yield put(actions.postResetPasswordFail({error: 'Something went wrong'}));
    }
  } else {
    yield put(actions.postResetPasswordFail({error: 'Something went wrong'}));
  }
}

export function* authSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actions.getCurrentUserStart.type, getCurrentUserSaga);
  yield takeEvery(actions.postLoginStart.type, postLoginSaga);
  yield takeEvery(actions.postLogoutStart.type, postLogoutSaga);
  yield takeEvery(actions.postForgotPasswordEmailStart.type, postForgotPasswordEmailSaga);
  yield takeEvery(actions.postResetPasswordStart.type, postResetPasswordSaga);
}
