import {
  call,
  ForkEffect,
  put,
  takeEvery,
  cancelled,
  race,
  take,
  select,
} from 'redux-saga/effects';
import {templateApi, containerApi, sectionApi, fieldApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';
import axios from 'axios';

// template listing page
function* getAllTemplatesSaga() {
  const endPoint = templateApi;

  yield put(actions.getAllTemplatesPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllTemplatesSuccess({data: response.data}));
    } else {
      yield put(actions.getAllTemplatesFail({error: {message: 'Something went wrong!'}}));
    }
  } else if (error) {
    yield put(actions.getAllTemplatesFail({error}));
  }
}

function* deleteTemplateSaga(action) {
  const {id} = action.payload;
  const endPoint = templateApi + '/' + id;
  yield put(actions.deleteTemplatePending());
  yield put(actions.addPendingTemplateRequest());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    yield put(actions.deleteTemplateSuccess({id}));
    yield put(actions.addSuccessTemplateRequest());
    // this is correct because we only allow deleting template on template listing page.
    // yield put(actions.getAllTemplatesStart());
  } else if (error) {
    yield put(actions.deleteTemplateFail({error}));
    yield put(actions.addFailTemplateRequest());
  }
}

// template edit page
function* getTemplateByIdSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {id, includeAll} = action.payload;
  const endPoint = templateApi + '/' + id + (includeAll ? '?include_all=true' : '');

  try {
    yield put(actions.getTemplateByIdPending());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'get',
      undefined,
      cancelSource.token
    );

    if (response && response.status === 200 && response.data) {
      // this is wrong but will work for now.
      if (response.data.data) {
        yield put(actions.getTemplateByIdSuccess({data: response.data}));
      } else {
        yield put(
          actions.getTemplateByIdFail({error: {message: 'something went wrong'}})
        );
      }
    } else if (error) {
      yield put(actions.getTemplateByIdFail({error}));
    }
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

// template
function* postTemplateSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {postObj} = action.payload;
  const endPoint = templateApi;

  try {
    yield put(actions.postTemplatePending());
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'post',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.postTemplateSuccess({data: response.data}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.postTemplateFail({error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putTemplateSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {postObj, id} = action.payload;
  const endPoint = templateApi + '/' + id;

  try {
    yield put(actions.putTemplatePending());
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putTemplateSuccess({data: response.data}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putTemplateFail({error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

// function* deleteTemplateSaga(action) {
//   const cancelSource = axios.CancelToken.source();
//   const {id} = action.payload;
//   const endPoint = templateApi + '/' + id;

//   try {
//     yield put(actions.deleteTemplatePending());
//     const {response, error} = yield call(
//       apiCall,
//       endPoint,
//       'delete',
//       id,
//       cancelSource.token,
//       {retry: 5, retryDelay: 500}
//     );

//     if (response && response.status === 200 && response.data) {
//       yield put(actions.deleteTemplateSuccess());
//     } else if (error) {
//       yield put(actions.deleteTemplateFail({error: error}));
//     }
//   } finally {
//     if (yield cancelled()) {
//       yield call(cancelSource.cancel);
//     }
//   }
// }

// container

function* postContainerSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {uid, order, postObj} = action.payload;
  const endPoint = containerApi;

  const parentSlice = yield select(({template}) => template.template);

  try {
    yield put(actions.postContainerPending({uid}));
    yield put(actions.addPendingTemplateRequest());

    // check to see if parent has id already
    // this will only happen on
    // template edit form or
    // template new form but after all default parts have been saved
    const finalPostObj = {
      ...postObj,
      field: {
        ...postObj.field,
        ...(parentSlice &&
          parentSlice.id &&
          parentSlice.isChildrenConnected && {
            template_id: parentSlice.id,
          }),
      },
    };

    const {response, error} = yield call(
      apiCall,
      endPoint,
      'post',
      finalPostObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.postContainerSuccess({uid, order, data: response.data}));
      yield put(actions.addSuccessTemplateRequest());

      // this only happens on first load of new template form.
      // or when new container just added to the form which trigger post request to save container, section and field at the same time.
      if (!parentSlice || !parentSlice.id || !parentSlice.isChildrenConnected) {
        yield put(
          actions.afterPostContainerSuccess({
            order,
            data: response.data,
          })
        );
      }
    } else if (error) {
      yield put(actions.postContainerFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putContainerSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {postObj, uid, id} = action.payload;
  const endPoint = containerApi + '/' + id;

  try {
    yield put(actions.putContainerPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putContainerSuccess({uid, data: response.data}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putContainerFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putContainersPositionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const endPoint = containerApi + '/position';

  const {postObj} = action.payload;

  try {
    yield put(actions.putContainersPositionPending());
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putContainersPositionSuccess());
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putContainersPositionFail({error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* deleteContainerSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {id, uid} = action.payload;
  const endPoint = containerApi + '/' + id;

  try {
    yield put(actions.deleteContainerPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'delete',
      id,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.deleteContainerSuccess({uid}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.deleteContainerFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

// section
function* postSectionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {uid, puid, order, postObj} = action.payload;
  const endPoint = sectionApi;

  const parentSlice = yield select(({template}) => template.template.containers[puid]);

  try {
    yield put(actions.postSectionPending({uid}));
    yield put(actions.addPendingTemplateRequest());

    // check to see if parent has id already
    // this will only happen on
    // template edit form or
    // template new form but after all default parts have been saved
    const finalPostObj = {
      ...postObj,
      field: {
        ...postObj.field,
        ...(parentSlice &&
          parentSlice.id &&
          parentSlice.isChildrenConnected && {container_id: parentSlice.id}),
      },
    };

    const {response, error} = yield call(
      apiCall,
      endPoint,
      'post',
      finalPostObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(
        actions.postSectionSuccess({
          uid,
          puid,
          order,
          data: response.data,
        })
      );
      yield put(actions.addSuccessTemplateRequest());

      // this only happens on first load of new template form.
      // or when new container just added to the form which trigger post request to save container, section and field at the same time.
      if (!parentSlice || !parentSlice.id || !parentSlice.isChildrenConnected) {
        yield put(
          actions.afterPostSectionSuccess({
            puid,
            order,
            data: response.data,
          })
        );
      }
    } else if (error) {
      yield put(actions.postSectionFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putSectionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {postObj, uid, id} = action.payload;
  const endPoint = sectionApi + '/' + id;

  try {
    yield put(actions.putSectionPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putSectionSuccess({uid, data: response.data}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putSectionFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putSectionsPositionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const endPoint = sectionApi + '/position';

  const {postObj} = action.payload;

  try {
    yield put(actions.putSectionsPositionPending());
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putSectionsPositionSuccess());
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putSectionsPositionFail({error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* deleteSectionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {id, uid} = action.payload;
  const endPoint = sectionApi + '/' + id;

  try {
    yield put(actions.deleteSectionPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'delete',
      id,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.deleteSectionSuccess({uid}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.deleteSectionFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

// field
function* postFieldSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {uid, puid, order, postObj} = action.payload;
  const endPoint = fieldApi;

  const parentSlice = yield select(({template}) => template.template.sections[puid]);

  try {
    yield put(actions.postFieldPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    // check to see if parent has id already
    // this will only happen on
    // template edit form or
    // template new form but after all default parts have been saved
    const finalPostObj = {
      ...postObj,
      input_field: {
        ...postObj.input_field,
        ...(parentSlice &&
          parentSlice.id &&
          parentSlice.isChildrenConnected && {section_id: parentSlice.id}),
      },
    };

    const {response, error} = yield call(
      apiCall,
      endPoint,
      'post',
      finalPostObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(
        actions.postFieldSuccess({
          uid,
          puid,
          order,
          data: response.data,
        })
      );

      yield put(actions.addSuccessTemplateRequest());
      // this only happens on first load of new template form.
      // or when new section just added to the form which trigger post request to save section and field at the same time.
      if (!parentSlice || !parentSlice.id || !parentSlice.isChildrenConnected) {
        yield put(
          actions.afterPostFieldSuccess({
            puid,
            order,
            data: response.data,
          })
        );
      }
    } else if (error) {
      yield put(actions.postFieldFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putFieldSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {postObj, uid, id} = action.payload;
  const endPoint = fieldApi + '/' + id;

  try {
    yield put(actions.putFieldPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putFieldSuccess({uid, data: response.data}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putFieldFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* putFieldsPositionSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const endPoint = fieldApi + '/position';

  const {postObj} = action.payload;

  try {
    yield put(actions.putFieldsPositionPending());
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'put',
      postObj,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.putFieldsPositionSuccess());
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.putFieldsPositionFail({error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

function* deleteFieldSaga(action) {
  const cancelSource = axios.CancelToken.source();
  const {id, uid} = action.payload;
  const endPoint = fieldApi + '/' + id;

  try {
    yield put(actions.deleteFieldPending({uid}));
    yield put(actions.addPendingTemplateRequest());
    const {response, error} = yield call(
      apiCall,
      endPoint,
      'delete',
      id,
      cancelSource.token,
      {retry: 5, retryDelay: 500}
    );

    if (response && response.status === 200 && response.data) {
      yield put(actions.deleteFieldSuccess({uid}));
      yield put(actions.addSuccessTemplateRequest());
    } else if (error) {
      yield put(actions.deleteFieldFail({uid, error: error}));
      yield put(actions.addFailTemplateRequest());
    }
  } finally {
    if (yield cancelled()) {
      yield put(actions.removePendingTemplateRequest());
      yield call(cancelSource.cancel);
    }
  }
}

export function* templateSaga(): Generator<ForkEffect<never>, void, unknown> {
  // template listing page
  yield takeEvery(actions.getAllTemplatesStart.type, getAllTemplatesSaga);
  yield takeEvery(actions.deleteTemplateStart.type, deleteTemplateSaga);

  // template edit page
  yield takeEvery(
    actions.getTemplateByIdStart.type,
    cancelable(getTemplateByIdSaga, actions.cancelAllTemplateRequests.type)
  );

  // template
  yield takeEvery(
    actions.postTemplateStart.type,
    cancelable(postTemplateSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putTemplateStart.type,
    cancelable(putTemplateSaga, actions.cancelAllTemplateRequests.type)
  );

  // container
  yield takeEvery(
    actions.postContainerStart.type,
    cancelable(postContainerSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putContainerStart.type,
    cancelable(putContainerSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.deleteContainerStart.type,
    cancelable(deleteContainerSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putContainersPositionStart.type,
    cancelable(putContainersPositionSaga, actions.cancelAllTemplateRequests.type)
  );

  // section
  yield takeEvery(
    actions.postSectionStart.type,
    cancelable(postSectionSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putSectionStart.type,
    cancelable(putSectionSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.deleteSectionStart.type,
    cancelable(deleteSectionSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putSectionsPositionStart.type,
    cancelable(putSectionsPositionSaga, actions.cancelAllTemplateRequests.type)
  );

  // field
  yield takeEvery(
    actions.postFieldStart.type,
    cancelable(postFieldSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putFieldStart.type,
    cancelable(putFieldSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.deleteFieldStart.type,
    cancelable(deleteFieldSaga, actions.cancelAllTemplateRequests.type)
  );
  yield takeEvery(
    actions.putFieldsPositionStart.type,
    cancelable(putFieldsPositionSaga, actions.cancelAllTemplateRequests.type)
  );
}

const cancelable = (saga, cancelAction) =>
  function* (...args) {
    yield race([call(saga, ...args), take(cancelAction)]);
  };
