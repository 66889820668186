import React from 'react';
import {motion} from 'framer-motion';
// material
import {Box} from '@material-ui/core';
//
import {varSmallClick, varMediumClick} from './variants';

// ----------------------------------------------------------------------

type Props = {
  mediumClick?: boolean | undefined;
  sx?: any;
  [x: string]: any;
};

const ButtonAnimate: React.FC<Props> = ({
  mediumClick = false,
  children,
  sx,
  ...other
}) => {
  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={mediumClick ? varMediumClick : varSmallClick}
      sx={{display: 'inline-flex', ...sx}}
      {...other}
    >
      {children}
    </Box>
  );
};

export default ButtonAnimate;
