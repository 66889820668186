import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {NavLink as RouterLink, matchPath, useLocation} from 'react-router-dom';
import {arrowIosForwardFill, arrowIosDownwardFill} from '../icons';

// material
import {alpha, useTheme, styled} from '@material-ui/core/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  // ListSubheader,
  ListItemButton,
} from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import {role} from 'minimal/utils/mock-data/role';

// ----------------------------------------------------------------------

const ListItemStyle = styled(props => <ListItemButton disableGutters {...props} />)(
  ({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: '#d1f0e0',
    },
  })
) as typeof ListItemButton;

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

function NavItem({item, active, isShow}) {
  const theme = useTheme();
  const {role: userRole, permission: userPermission} = useAuth();
  const isActiveRoot = active(item.path);
  const {title, path, icon, info, children, disable, roles} = item;
  const [open, setOpen] = useState(isActiveRoot);

  // need this to make sure it always gonna show sub menu items when root is active.
  // remove this if run into any weird issue.
  useEffect(() => {
    setOpen(isActiveRoot);
  }, [isActiveRoot]);

  const handleOpen = () => {
    setOpen(prev => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    bgcolor: '#d1f0e0',
    '&:before': {display: 'block'},
  };

  const activeSubStyle = {
    color: 'text.primary',
    // fontWeight: 'fontWeightMedium',
    fontWeight: 600,
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            color: '#081C41',
            fontWeight: '500',
            ...(isActiveRoot && activeRootStyle),
          }}
          disabled={disable || false}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{width: 16, height: 16, ml: 1}}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map(item => {
                const {title, path, disable} = item;
                const isActiveSub = active(path);
                return item.roles && item.roles.includes(userRole) ? (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      color: '#081C41',
                      fontWeight: '500',
                      ...(isActiveSub && activeSubStyle),
                    }}
                    disabled={disable || false}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: theme => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                ) : undefined;
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        color: '#081C41',
        fontWeight: '600',
        ...(isActiveRoot && activeRootStyle),
      }}
      disabled={disable || false}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

type NavSectionPropType = {
  isShow?: boolean;
  navConfig: {[x: string]: any}[];
  [x: string]: any;
};

const NavSection: React.FC<NavSectionPropType> = ({
  navConfig,
  isShow = true,
  ...other
}) => {
  const {pathname} = useLocation();
  const {role: userRole, permission: userPermission} = useAuth();
  const match = path => (path ? !!matchPath({path, end: false}, pathname) : false);

  return (
    <Box {...other}>
      {navConfig.map(list => {
        const {subheader, items} = list;
        return (
          <List key={subheader} disablePadding>
            {/* {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>} */}
            {items.map(item => {
              return item.roles && item.roles.includes(userRole) ? (
                <NavItem key={item.title} item={item} active={match} isShow={isShow} />
              ) : undefined;
            })}
          </List>
        );
      })}
    </Box>
  );
};

export default NavSection;
