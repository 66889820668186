import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  inspection: {
    id: null,
    pending: false,
    data: null,
    error: null,
    success: false,
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    create: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
    status: {
      pending: false,
      data: null,
      error: null,
      success: false,
      value: null,
    },
    notes: {
      // this contains all notes being updated dynamically.
      // the key of the object will be the id of the field.
    },
    fields: {
      // this contains all fields being updated dynamically.
      // the key of the object will be the id of the field.
    },
    files: {
      // this contains all image / file objects being updated dynamically,
      // the key of the file object will be the uid of the image itself.
    },
    deleteFiles: {
      // this contains all image / file objects being deleted dynamically,
      // the key of the file object will be the secretKey of the image itself.
    },
    requests: {
      pendingCount: 0,
      failCount: 0,
      successCount: 0,
    },
    fileRequests: {
      pendingCount: 0,
      failCount: 0,
      successCount: 0,
    },
    updateLocation: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
  inspectionList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    delete: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
  },
};

// inspection slice
const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    // new inspection
    postInspectionStart: (state, action) => {},
    postInspectionPending: state => {
      state.inspection.create = {
        ...state.inspection.create,
        pending: true,
      };
    },
    postInspectionFail: (state, action) => {
      const {error} = action.payload;
      state.inspection.create = {
        ...state.inspection.create,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postInspectionSuccess: (state, action) => {
      const {data} = action.payload;
      state.inspection.create = {
        ...state.inspection.create,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPostInspectionState: state => {
      state.inspection.create = initialState.inspection.create;
    },
    // update inspection
    putInspectionByIdStart: (state, action) => {
      state.inspection.update = initialState.inspection.update;
    },
    putInspectionByIdPending: state => {
      state.inspection.update = {
        ...state.inspection.update,
        pending: true,
      };
    },
    putInspectionByIdFail: (state, action) => {
      const {error} = action.payload;
      state.inspection.update = {
        ...state.inspection.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putInspectionByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.inspection.update = {
        ...state.inspection.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutInspectionState: state => {
      state.inspection.update = initialState.inspection.update;
    },
    // delete inspection
    deleteInspectionByIdStart: (state, action) => {
      const {id} = action.payload;
      state.inspectionList.delete = {
        ...state.inspectionList.delete,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    deleteInspectionByIdPending: state => {
      state.inspectionList.delete = {
        ...state.inspectionList.delete,
        pending: true,
      };
    },
    deleteInspectionByIdFail: (state, action) => {
      const {error} = action.payload;
      state.inspectionList.delete = {
        ...state.inspectionList.delete,
        pending: false,
        error: error,
        success: false,
      };
    },
    deleteInspectionByIdSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.inspectionList.items};
      delete updatedItems[id];

      state.inspectionList = {
        ...state.inspectionList,
        items: updatedItems,
      };

      state.inspectionList.delete = {
        ...state.inspectionList.delete,
        pending: false,
        error: null,
        success: true,
      };
    },
    // reset inspection single page redux state
    resetInspectionState: state => {
      state.inspection = initialState.inspection;
    },
    // we might don't need this at all.
    cancelAllInspectionRequests: () => {},
    // inspection listing
    getAllInspectionsStart: state => {
      state.inspectionList = {
        ...state.inspectionList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllInspectionsPending: state => {
      state.inspectionList = {
        ...state.inspectionList,
        pending: true,
      };
    },
    getAllInspectionsFail: (state, action) => {
      const {error} = action.payload;
      state.inspectionList = {
        ...state.inspectionList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllInspectionsSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.inspectionList = {
        ...state.inspectionList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetInspectionListState: state => {
      state.inspectionList = initialState.inspectionList;
    },
    // inspection edit page
    getInspectionByIdStart: (state, action) => {
      state.inspection = initialState.inspection;
    },
    getInspectionByIdPending: state => {
      state.inspection.pending = true;
    },
    getInspectionByIdFail: (state, action) => {
      const {error} = action.payload;
      state.inspection = {
        ...state.inspection,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    getInspectionByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.inspection = {
        ...state.inspection,
        id: data.data.id,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    // inspection edit page
    putInspectionFieldByIdStart: (state, action) => {
      const {id} = action.payload;
      state.inspection.fields[id] = {
        ...state.template.fields[id],
        update: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putInspectionFieldPending: (state, action) => {
      const {id} = action.payload;
      state.inspection.fields[id].update = {
        ...state.template.fields[id].update,
        pending: true,
      };
    },
    putInspectionFieldFail: (state, action) => {
      const {id, error} = action.payload;
      state.inspection.fields[id].update = {
        ...state.template.fields[id].update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putInspectionFieldSuccess: (state, action) => {
      const {id, data} = action.payload;
      state.inspection.fields[id].update = {
        ...state.inspection.fields[id].update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },

    // Inspection edit page
    // POST inspection input fields
    postInspectionInputFieldStart: (state, action) => {
      const {id} = action.payload;
      state.inspection.fields = {
        ...state.inspection.fields,
        [id]: {
          pending: false,
          data: null,
          error: null,
          success: false,
          payload: action.payload,
        },
      };
    },
    postInspectionInputFieldPending: (state, action) => {
      const {id} = action.payload;
      state.inspection.fields[id] = {
        ...state.inspection.fields[id],
        pending: true,
      };
    },
    postInspectionInputFieldFail: (state, action) => {
      const {error, id} = action.payload;
      state.inspection.fields[id] = {
        ...state.inspection.fields[id],
        pending: false,
        data: null,
        error: error.message,
        success: false,
      };
    },
    postInspectionInputFieldSuccess: (state, action) => {
      const {data, id} = action.payload;
      state.inspection.fields[id] = {
        ...state.inspection.fields[id],
        pending: false,
        data: data,
        error: null,
        success: true,
      };
    },

    // Inspection edit page
    // DELETE inspection image by field id and key
    deleteInspectionInputFileStart: (state, action) => {
      const {secretKey} = action.payload;
      const tempObj = {};
      if (_.isArray(secretKey)) {
        // delete multiple files at the same time
        _.each(secretKey, key => {
          tempObj[key] = {
            pending: false,
            data: null,
            error: null,
            success: false,
          };
        });
      } else {
        // delete single file
        tempObj[secretKey] = {
          pending: false,
          data: null,
          error: null,
          success: false,
        };
      }
      state.inspection.deleteFiles = {
        ...state.inspection.deleteFiles,
        ...tempObj,
      };
    },
    deleteInspectionInputFilePending: (state, action) => {
      const {secretKey} = action.payload;

      state.inspection.deleteFiles[secretKey] = {
        ...state.inspection.deleteFiles[secretKey],
        pending: true,
      };
    },
    deleteInspectionInputFileFail: (state, action) => {
      const {error, secretKey} = action.payload;

      state.inspection.deleteFiles[secretKey] = {
        ...state.inspection.deleteFiles[secretKey],
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    deleteInspectionInputFileSuccess: (state, action) => {
      const {data, secretKey} = action.payload;

      state.inspection.deleteFiles[secretKey] = {
        ...state.inspection.deleteFiles[secretKey],
        pending: false,
        data: data,
        error: null,
        success: true,
      };
    },

    // Create / update note of input field
    putInspectionInputNoteStart: (state, action) => {
      const {id} = action.payload;
      state.inspection.notes = {
        ...state.inspection.notes,
        [id]: {
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    putInspectionInputNotePending: (state, action) => {
      const {id} = action.payload;
      state.inspection.notes[id] = {
        ...state.inspection.notes[id],
        pending: true,
      };
    },
    putInspectionInputNoteFail: (state, action) => {
      const {error, id} = action.payload;
      state.inspection.notes[id] = {
        ...state.inspection.notes[id],
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putInspectionInputNoteSuccess: (state, action) => {
      const {data, id} = action.payload;
      state.inspection.notes[id] = {
        ...state.inspection.notes[id],
        pending: false,
        data: data,
        error: null,
        success: true,
      };
    },

    // inspection edit page
    // update inspection status
    putInspectionStatusStart: (state, action) => {
      state.inspection.status = initialState.inspection.status;
    },
    putInspectionStatusPending: (state, action) => {
      state.inspection.status.pending = true;
    },
    putInspectionStatusFail: (state, action) => {
      const {error} = action.payload;
      state.inspection.status = {
        ...state.inspection.status,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putInspectionStatusSuccess: (state, action) => {
      const {data, status} = action.payload;
      state.inspection.status = {
        ...state.inspection.status,
        pending: false,
        data: data,
        error: null,
        success: true,
        value: status,
      };
    },
    resetInspectionStatusState: state => {
      state.inspection.status = initialState.inspection.status;
    },

    // POST image / file of upload file input field
    postInspectionInputFileStart: (state, action) => {
      const {id, uid} = action.payload;
      state.inspection.files = {
        ...state.inspection.files,
        [uid]: {
          id: id,
          pending: false,
          data: null,
          error: null,
          success: false,
        },
      };
    },
    postInspectionInputFilePending: (state, action) => {
      const {uid} = action.payload;
      state.inspection.files[uid] = {
        ...state.inspection.files[uid],
        pending: true,
      };
    },
    postInspectionInputFileFail: (state, action) => {
      const {error, uid} = action.payload;
      state.inspection.files[uid] = {
        ...state.inspection.files[uid],
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postInspectionInputFileSuccess: (state, action) => {
      const {data, uid} = action.payload;
      state.inspection.files[uid] = {
        ...state.inspection.files[uid],
        pending: false,
        data: data,
        error: null,
        success: true,
      };
    },

    // inspection edit page
    // keep track of inspection field requests
    addPendingInspectionRequest: state => {
      state.inspection.requests = {
        ...state.inspection.requests,
        pendingCount: state.inspection.requests.pendingCount + 1,
      };
    },
    removePendingInspectionRequest: state => {
      state.inspection.requests = {
        ...state.inspection.requests,
        pendingCount: state.inspection.requests.pendingCount - 1,
      };
    },
    addFailInspectionRequest: state => {
      state.inspection.requests = {
        ...state.inspection.requests,
        failCount: state.inspection.requests.failCount + 1,
        pendingCount: state.inspection.requests.pendingCount - 1,
      };
    },
    addSuccessInspectionRequest: state => {
      state.inspection.requests = {
        ...state.inspection.requests,
        successCount: state.inspection.requests.successCount + 1,
        pendingCount: state.inspection.requests.pendingCount - 1,
      };
    },

    // inspection edit page
    // keep track of inspection file upload requests
    addPendingFileRequest: state => {
      state.inspection.fileRequests = {
        ...state.inspection.fileRequests,
        pendingCount: state.inspection.fileRequests.pendingCount + 1,
      };
    },
    removePendingFileRequest: state => {
      state.inspection.fileRequests = {
        ...state.inspection.fileRequests,
        pendingCount: state.inspection.fileRequests.pendingCount - 1,
      };
    },
    addFailFileRequest: state => {
      state.inspection.fileRequests = {
        ...state.inspection.fileRequests,
        failCount: state.inspection.fileRequests.failCount + 1,
        pendingCount: state.inspection.fileRequests.pendingCount - 1,
      };
    },
    addSuccessFileRequest: state => {
      state.inspection.fileRequests = {
        ...state.inspection.fileRequests,
        successCount: state.inspection.fileRequests.successCount + 1,
        pendingCount: state.inspection.fileRequests.pendingCount - 1,
      };
    },

    // reset inspection listing redux state
    resetInspectionsState: state => {
      state.inspectionList = initialState.inspectionList;
    },

    // put location for current inspection
    putInspectionLocationByIdStart: (state, action) => {},
    putInspectionLocationByIdPending: state => {
      state.inspection.updateLocation = {
        ...initialState.inspection.updateLocation,
        pending: true,
      };
    },
    putInspectionLocationByIdFail: (state, action) => {
      const {error} = action.payload;
      state.inspection.updateLocation = {
        ...state.inspection.updateLocation,
        pending: false,
        error: error,
        success: false,
      };
    },
    putInspectionLocationByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.inspection.updateLocation = {
        ...state.inspection.updateLocation,
        pending: false,
        error: null,
        data: data.data,
        success: false,
      };
    },
  },
});

const {actions, reducer} = inspectionSlice;
export {actions, reducer};
