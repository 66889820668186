import React from 'react';
import {useMemo} from 'react';
// material
import {CssBaseline} from '@material-ui/core';
import {createTheme, ThemeProvider, StyledEngineProvider} from '@material-ui/core/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';
import {ThemeOptions} from '@material-ui/core';
import {Shadows} from '@material-ui/core/styles/shadows';

// ----------------------------------------------------------------------

const ThemeConfig: React.FC = ({children}) => {
  const {themeMode, themeDirection} = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight
        ? {...palette.light, mode: 'light'}
        : {...palette.dark, mode: 'dark'},
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      shadows: isLight ? (shadows.light as Shadows) : (shadows.dark as Shadows),
    }),
    [isLight, themeDirection]
  ) as ThemeOptions;

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
