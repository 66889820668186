import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  // at the moment we don't have user single page
  // maybe in the future
  // user: {
  //   id: null,
  //   pending: false,
  //   data: null,
  //   error: null,
  //   success: false,
  //   update: {
  //     pending: false,
  //     data: null,
  //     error: null,
  //     success: false,
  //   },
  //   create: {
  //     pending: false,
  //     data: null,
  //     error: null,
  //     success: false,
  //   },
  // },
  userInvite: {
    pending: false,
    data: null,
    error: null,
    success: false,
  },
  userInviteVerify: {
    pending: false,
    data: null,
    error: null,
    success: false,
  },
  userList: {
    items: null,
    pending: false,
    error: null,
    success: false,
    searchParams: {},
    archive: {
      pending: false,
      error: null,
      success: false,
      id: null,
    },
    update: {
      pending: false,
      data: null,
      error: null,
      success: false,
    },
  },
};

// user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // invite new user
    postUserInviteStart: (state, action) => {},
    postUserInvitePending: state => {
      state.userInvite.pending = true;
    },
    postUserInviteFail: (state, action) => {
      const {error} = action.payload;
      state.userInvite = {
        ...state.userInvite,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postUserInviteSuccess: (state, action) => {
      const {data} = action.payload;
      state.userInvite = {
        ...state.userInvite,
        pending: false,
        data: data.message,
        error: null,
        success: true,
      };
    },
    resetPostUserInviteState: state => {
      state.userInvite = initialState.userInvite;
    },

    // verify invite link for new user
    postUserInviteVerifyStart: (state, action) => {},
    postUserInviteVerifyPending: state => {
      state.userInviteVerify.pending = true;
    },
    postUserInviteVerifyFail: (state, action) => {
      const {error} = action.payload;
      state.userInviteVerify = {
        ...state.userInviteVerify,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    postUserInviteVerifySuccess: (state, action) => {
      const {data} = action.payload;
      state.userInviteVerify = {
        ...state.userInviteVerify,
        pending: false,
        data: data,
        error: null,
        success: true,
      };
    },
    resetPostUserInviteVerifyState: state => {
      state.userInviteVerify = initialState.userInviteVerify;
    },

    // at the moment we don't have user single page
    // maybe in the future
    // user edit page
    // getUserByIdStart: (state, action) => {
    //   state.user = initialState.user;
    // },
    // getUserByIdPending: state => {
    //   state.user.pending = true;
    // },
    // getUserByIdFail: (state, action) => {
    //   const {error} = action.payload;
    //   state.user = {
    //     ...state.user,
    //     pending: false,
    //     data: null,
    //     error: error,
    //     success: false,
    //   };
    // },
    // getUserByIdSuccess: (state, action) => {
    //   const {data} = action.payload;
    //   state.user = {
    //     ...state.user,
    //     id: data.data.id,
    //     pending: false,
    //     data: data.data,
    //     error: null,
    //     success: true,
    //   };
    // },

    // update user
    putUserByIdStart: (state, action) => {
      state.userList.update = initialState.userList.update;
    },
    putUserByIdPending: state => {
      state.userList.update = {
        ...state.userList.update,
        pending: true,
      };
    },
    putUserByIdFail: (state, action) => {
      const {error} = action.payload;
      state.userList.update = {
        ...state.userList.update,
        pending: false,
        data: null,
        error: error,
        success: false,
      };
    },
    putUserByIdSuccess: (state, action) => {
      const {data} = action.payload;
      state.userList.update = {
        ...state.userList.update,
        pending: false,
        data: data.data,
        error: null,
        success: true,
      };
    },
    resetPutUserState: state => {
      state.userList.update = initialState.userList.update;
    },
    // archive user
    archiveUserByIdStart: (state, action) => {
      const {id} = action.payload;
      state.userList.archive = {
        ...state.userList.archive,
        pending: false,
        error: null,
        success: false,
        id: id,
      };
    },
    archiveUserByIdPending: state => {
      state.userList.archive = {
        ...state.userList.archive,
        pending: true,
      };
    },
    archiveUserByIdFail: (state, action) => {
      const {error} = action.payload;
      state.userList.archive = {
        ...state.userList.archive,
        pending: false,
        error: error,
        success: false,
      };
    },
    archiveUserByIdSuccess: (state, action) => {
      const {id} = action.payload;

      // later on if we have to use API to update the list, remove this.
      const updatedItems = {...state.userList.items};
      delete updatedItems[id];

      state.userList = {
        ...state.userList,
        items: updatedItems,
      };

      state.userList.archive = {
        ...state.userList.archive,
        pending: false,
        error: null,
        success: true,
      };
    },
    // user listing
    getAllUsersStart: (state, action) => {
      state.userList = {
        ...state.userList,
        items: null,
        pending: false,
        error: null,
        success: false,
        searchParams: {},
      };
    },
    getAllUsersPending: state => {
      state.userList = {
        ...state.userList,
        pending: true,
      };
    },
    getAllUsersFail: (state, action) => {
      const {error} = action.payload;
      state.userList = {
        ...state.userList,
        pending: false,
        error: error,
        success: false,
        items: null,
      };
    },
    getAllUsersSuccess: (state, action) => {
      const {data} = action.payload;
      const dataObj = _.keyBy(
        _.each(data.data, (template, index) => {
          return _.extend(template, {order: index});
        }),
        'id'
      );

      state.userList = {
        ...state.userList,
        items: dataObj,
        pending: false,
        error: null,
        success: true,
        searchParams: {}, // for now, later on we definitely need this object.
      };
    },
    resetUserListState: state => {
      state.userList = initialState.userList;
    },
  },
});

const {actions, reducer} = userSlice;
export {actions, reducer};
