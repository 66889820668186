import {call, ForkEffect, put, takeEvery, takeLatest, all} from 'redux-saga/effects';
import {inspectionApi, fieldApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';
import * as _ from 'lodash';

// inspection listing page
function* getAllInspectionsSaga() {
  const endPoint = inspectionApi;

  yield put(actions.getAllInspectionsPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getAllInspectionsSuccess({data: response.data}));
    } else {
      yield put(
        actions.getAllInspectionsFail({error: {message: 'Something went wrong!'}})
      );
    }
  } else if (error) {
    yield put(actions.getAllInspectionsFail({error: {message: 'Something went wrong!'}}));
  }
}

// inspection listing page - delete inspection by id
function* deleteInspectionByIdSaga(action) {
  const {id} = action.payload;
  const endPoint = inspectionApi + '/' + id;
  yield put(actions.deleteInspectionByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'delete');

  if (response && response.status === 200 && response.data) {
    yield put(actions.deleteInspectionByIdSuccess({id}));
  } else if (error) {
    yield put(
      actions.deleteInspectionByIdFail({error: {message: 'something went wrong'}})
    );
  }
}

// TODO: testing strings on things of the multiple upload
// remove once working.
// https://sir-staging.s3.ap-southeast-2.amazonaws.com/i6oX7Hxzu7KJ8TeP73vPA6vu?
// X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATUJRY5TKBVZRUK56%2F20220117%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20220117T061823Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=6c3c9fd9c03bbd0df88469a7733fce0e79f53902e149f6c8b5ee545c0825efe8

// https://sir-staging.s3.ap-southeast-2.amazonaws.com/PcUth7v8ada1KXBwP8L4ZniC?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIATUJRY5TKBVZRUK56%2F20220117%2Fap-southeast-2%2Fs3%2Faws4_request\u0026X-Amz-Date=20220117T061132Z\u0026X-Amz-Expires=900\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=01078c38603adf479e462601fba982ab6be11b4065b0e23a7fe804298b121b80

// inspection new page
function* postInspectionSaga(action) {
  const {postObj} = action.payload;
  const endPoint = inspectionApi;

  yield put(actions.postInspectionPending());
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.postInspectionSuccess({data: response.data}));
    } else {
      // TODO: backend have to handle this in a more structured way.
      yield put(actions.postInspectionFail({error: {message: 'something went wrong'}}));
    }
  } else if (error) {
    yield put(actions.postInspectionFail({error: {message: 'something went wrong'}}));
  }
}

// inspection edit page
function* getInspectionByIdSaga(action) {
  const {id, includeAll} = action.payload;
  const endPoint = inspectionApi + '/' + id + (includeAll ? '?include_all=true' : '');

  yield put(actions.getInspectionByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now.
    if (response.data.data) {
      yield put(actions.getInspectionByIdSuccess({data: response.data}));
    } else {
      yield put(
        actions.getInspectionByIdFail({error: {message: 'something went wrong'}})
      );
    }
  } else if (error) {
    yield put(actions.getInspectionByIdFail({error: {message: 'something went wrong'}}));
  }
}

// inspection edit page - update inspection by id
function* putInspectionByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = inspectionApi + '/' + id;

  yield put(actions.putInspectionByIdPending());
  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    if (response.data.data) {
      yield put(actions.putInspectionByIdSuccess({data: response.data}));
    } else {
      yield put(
        actions.putInspectionByIdFail({error: {message: 'something went wrong'}})
      );
    }
  } else {
    yield put(actions.putInspectionByIdFail({error: {message: 'something went wrong'}}));
  }
}

// inspection edit page - update inspection field by field id
// according to API, it's POST request
function* postInspectionInputFieldSaga(action) {
  const {postObj, id} = action.payload;
  const isFile = action.payload.isFile ? true : false;
  const endPoint = inspectionApi + '/input_field';

  yield put(actions.postInspectionInputFieldPending({id}));
  yield put(actions.addPendingInspectionRequest());
  if (isFile) {
    yield put(actions.addPendingFileRequest());
  }
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);

  if (response && response.status === 200 && response.data) {
    yield put(actions.postInspectionInputFieldSuccess({data: response.data, id}));
    yield put(actions.addSuccessInspectionRequest());
    if (isFile) {
      yield put(actions.addSuccessFileRequest());
    }
  } else {
    yield put(
      actions.postInspectionInputFieldFail({error: {message: 'something went wrong'}, id})
    );
    yield put(actions.addFailInspectionRequest());
    if (isFile) {
      yield put(actions.addFailFileRequest());
    }
  }
}

// inspection edit page - update inspection note by field id
// according to API, it's PUT request
function* putInspectionInputNoteSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = fieldApi + '/' + id;

  yield put(actions.putInspectionInputNotePending({id}));
  yield put(actions.addPendingInspectionRequest());
  const {response, error} = yield call(apiCall, endPoint, 'PUT', postObj);

  if (response && response.status === 200 && response.data) {
    yield put(actions.putInspectionInputNoteSuccess({data: response.data, id}));
    yield put(actions.addSuccessInspectionRequest());
  } else {
    yield put(
      actions.putInspectionInputNoteFail({error: {message: 'something went wrong'}, id})
    );
    yield put(actions.addFailInspectionRequest());
  }
}

// inspection edit page - update inspection status by inspection id
function* putInspectionStatusSaga(action) {
  const {id, status, postObj} = action.payload;
  const endPoint = inspectionApi + '/' + id + '/status';

  yield put(actions.putInspectionStatusPending({id}));
  const {response, error} = yield call(apiCall, endPoint, 'PATCH', postObj);

  if (response && response.status === 200 && response.data) {
    yield put(actions.putInspectionStatusSuccess({data: response.data, status}));
  } else {
    yield put(
      actions.putInspectionStatusFail({error: {message: 'something went wrong'}})
    );
  }
}

// inspection edit page - upload file / image for image upload input field.
function* postInspectionInputFileSaga(action) {
  const {fileObj, uid, id} = action.payload;
  const endPoint = inspectionApi + '/input_field/file';

  const formData = new FormData();
  formData.append('file', fileObj);

  yield put(actions.postInspectionInputFilePending({uid}));
  yield put(actions.addPendingInspectionRequest());
  yield put(actions.addPendingFileRequest());
  const {response, error} = yield call(apiCall, endPoint, 'post', formData);

  if (response && response.status === 200 && response.data) {
    yield put(actions.postInspectionInputFileSuccess({data: response.data, uid}));

    if (response.data && response.data.data && response.data.data.key) {
      const postObj = {
        input_field_value: {
          input_field_id: id,
          body: `["${response.data.data.key}"]`,
        },
      };
      // actually save in the input_field data attributes "body"
      // TODO: need update from backend to allow multiple images
      yield put(actions.postInspectionInputFieldStart({postObj, id, isFile: true}));
    }
    yield put(actions.addSuccessInspectionRequest());
    yield put(actions.addSuccessFileRequest());
  } else {
    yield put(
      actions.postInspectionInputFileFail({error: {message: 'something went wrong'}, uid})
    );
    yield put(actions.addFailInspectionRequest());
    yield put(actions.addFailFileRequest());
  }
}

// inspection edit page - delete image
function* deleteInspectionInputFileSaga(action) {
  const {secretKey, id, postObj} = action.payload;
  const endPoint = inspectionApi + '/input_field/file/' + id;

  if (_.isArray(secretKey)) {
    yield all(secretKey.map(key => call(singleDeleteInspectionInputFilePending, key)));
  } else {
    yield call(singleDeleteInspectionInputFilePending, secretKey);
  }
  const {response, error} = yield call(apiCall, endPoint, 'delete', postObj);
  if (response && response.status === 200 && response.data) {
    if (_.isArray(secretKey)) {
      yield all(secretKey.map(key => call(singleDeleteInspectionInputFileSuccess, key)));
    } else {
      yield call(singleDeleteInspectionInputFileSuccess, secretKey);
    }
  } else if (error) {
    if (_.isArray(secretKey)) {
      yield all(secretKey.map(key => call(singleDeleteInspectionInputFileFail, key)));
    } else {
      yield call(singleDeleteInspectionInputFileFail, secretKey);
    }
  }
}

// related to above deleteInspectionInputFileSaga
function* singleDeleteInspectionInputFilePending(secretKey: string) {
  yield put(actions.deleteInspectionInputFilePending({secretKey}));
  yield put(actions.addPendingInspectionRequest());
  yield put(actions.addPendingFileRequest());
}
function* singleDeleteInspectionInputFileSuccess(secretKey: string) {
  yield put(actions.deleteInspectionInputFileSuccess({secretKey}));
  yield put(actions.addSuccessInspectionRequest());
  yield put(actions.addSuccessFileRequest());
}
function* singleDeleteInspectionInputFileFail(secretKey: string) {
  yield put(
    actions.deleteInspectionInputFileFail({
      secretKey,
      error: {message: 'something went wrong'},
    })
  );
  yield put(actions.addFailInspectionRequest());
  yield put(actions.addFailFileRequest());
}

// inspection edit page - update location of current inspection
function* putInspectionLocationByIdSaga(action) {
  const {postObj, id} = action.payload;
  const endPoint = inspectionApi + '/' + id + '/update_location';

  yield put(actions.putInspectionLocationByIdPending());
  yield put(actions.addPendingInspectionRequest());
  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);

  if (response && response.status === 200 && response.data) {
    if (response.data.data) {
      yield put(actions.putInspectionLocationByIdSuccess({data: response.data}));
      yield put(actions.addSuccessInspectionRequest());
    } else {
      yield put(
        actions.putInspectionLocationByIdFail({error: {message: 'something went wrong'}})
      );
      yield put(actions.addFailInspectionRequest());
    }
  } else {
    yield put(
      actions.putInspectionLocationByIdFail({error: {message: 'something went wrong'}})
    );
    yield put(actions.addFailInspectionRequest());
  }
}

export function* inspectionSaga(): Generator<ForkEffect<never>, void, unknown> {
  // inspection listing page
  yield takeLatest(actions.getAllInspectionsStart.type, getAllInspectionsSaga);
  // inspection listing page - delete inspection by id
  yield takeEvery(actions.deleteInspectionByIdStart.type, deleteInspectionByIdSaga);
  // inspection new page
  yield takeEvery(actions.postInspectionStart.type, postInspectionSaga);
  // inspection edit page
  yield takeLatest(actions.getInspectionByIdStart.type, getInspectionByIdSaga);
  // inspection edit page - update inspection by id
  yield takeEvery(actions.putInspectionByIdStart.type, putInspectionByIdSaga);
  // inspection edit page - save input_field values on blur
  yield takeEvery(actions.postInspectionInputFieldStart, postInspectionInputFieldSaga);
  // inspection edit page - save input_field note on saved / blur
  yield takeEvery(actions.putInspectionInputNoteStart, putInspectionInputNoteSaga);
  // inspection edit page - save input_field file / image on added
  yield takeEvery(actions.postInspectionInputFileStart, postInspectionInputFileSaga);
  // inspection edit page - change inspection status
  yield takeEvery(actions.putInspectionStatusStart, putInspectionStatusSaga);
  // inspection edit page - delete inspection file / image
  yield takeEvery(actions.deleteInspectionInputFileStart, deleteInspectionInputFileSaga);
  // inspection edit page - update inspection location by id
  yield takeEvery(actions.putInspectionLocationByIdStart, putInspectionLocationByIdSaga);
}
