import React, {useState} from 'react';
// material
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';

// icons
import {Icon} from '@iconify/react';
import {plusFill, ticket} from '../../icons';
import HtmlTicketForm from './HtmlTicketForm';
import IFrameTicketForm from './IFrameTicketForm';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const SidebarTicketButton: React.FC = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const onDialogClose = (e, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      // do nothing
    } else {
      setIsShowDialog(false);
      setIsShowSpinner(true);
    }
  };

  const onLoad = () => {
    setIsShowSpinner(false);
  };

  return (
    <>
      {/* Submit ticket button  */}
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        // startIcon={<Icon icon={plusFill} />}
        startIcon={<Icon icon={ticket} />}
        sx={{
          // backgroundColor: '#001632',
          // textTransform: 'uppercase',
          borderRadius: '12px',
          p: '5px 10px',
          boxShadow: 0,
          m: '20px',
          fontSize: '12px',
          width: 'calc(100% - 40px)',
        }}
        onClick={() => {
          setIsShowDialog(true);
        }}
      >
        Submit Ticket
      </Button>

      {/* Submit ticket form  */}
      <Dialog
        open={isShowDialog}
        onClose={onDialogClose}
        scroll={'paper'}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Ticket Submission</DialogTitle>
        <DialogContent dividers={true} tabIndex={-1}>
          <Box sx={{position: 'relative'}}>
            {isShowSpinner && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 20px)',
                  left: 'calc(50% - 20px)',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
            {/* <HtmlTicketForm /> */}
            <IFrameTicketForm onLoad={onLoad} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              variant="contained"
              color="inherit"
              sx={{mr: 1}}
              onClick={() => {
                setIsShowDialog(false);
                setIsShowSpinner(true);
              }}
            >
              Exit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarTicketButton;
