import React from 'react';
// material
import {Box, useMediaQuery, Theme} from '@material-ui/core';
import {ReactComponent as LogoBlue} from '../assets/SIR_logo_blue.svg';
import {ReactComponent as LogoWhite} from '../assets/SIR_logo_white.svg';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

type LogoPropType = {
  sx?: {[x: string]: any};
};

const Logo: React.FC<LogoPropType> = ({sx}): JSX.Element => {
  // is use is not authenticated, Authguard will force to display login / register component even if the route is differernt
  const {isAuthenticated} = useAuth();
  const isTabletOrMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={{width: 140, ...sx}}>
      {(isAuthenticated || (!isAuthenticated && isTabletOrMobile)) && <LogoBlue />}
      {!isAuthenticated && isDesktop && <LogoWhite />}
    </Box>
  );
};

export default Logo;
