import * as _ from 'lodash';
import {call, ForkEffect, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {subscriptionApi} from 'configApi';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';

import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';

// subsription setting page
function* getAllSubscriptionsSaga(action) {
  const {includeAll} = action.payload;
  const endPoint = subscriptionApi + '/' + (includeAll ? '?include_all=true' : '');

  yield put(actions.getAllSubscriptionsPending());
  const {response, error} = yield call(apiCall, endPoint, 'get');

  if (response && response.status === 200 && response.data) {
    // this is wrong but will work for now. until back-end come up with a more structured way of handling api stuff.
    if (response.data.data) {
      yield put(actions.getAllSubscriptionsSuccess({data: response.data}));
    } else {
      yield put(
        actions.getAllSubscriptionsFail({error: {message: 'something went wrong'}})
      );
    }
  } else if (error) {
    yield put(
      actions.getAllSubscriptionsFail({error: {message: 'something went wrong'}})
    );
  }
}

export function* subscriptionSaga(): Generator<ForkEffect<never>, void, unknown> {
  // subscription listing page
  yield takeLatest(actions.getAllSubscriptionsStart.type, getAllSubscriptionsSaga);
}
