import {call, ForkEffect, put, takeEvery} from 'redux-saga/effects';
import {actions} from './slice';
import {apiCall} from '../../utils/axios';
import {userApi} from 'configApi';

function* postRegistrationEmailSaga(action) {
  const endPoint = userApi;
  const {email, password} = action.payload;
  const postObj: any = {
    field: {
      email: email,
    },
  };
  const {response, error} = yield call(apiCall, endPoint, 'post', postObj);
  if (response && response.data && response.data.status === 'Created') {
    yield put(
      actions.postRegisterEmailSuccess({
        email: email,
        id: response.data.data.id,
      })
    );
  } else {
    yield put(
      actions.postRegisterEmailFail({error: 'Please choose another email address.'})
    );
  }
  // before bug fix, not sure what was changed in the back-end
  /* if (response && response.data && response.data.status === 'success') {
    yield put(
      actions.postRegisterEmailSuccess({
        email: response.data.data.attributes.email,
        id: response.data.data.id,
      })
    );
  } else {
    yield put(actions.postRegisterEmailFail({error: 'Email already registered'}));
  } */
}

function* postEmailVerificationSaga(action) {
  const {verifyCode, userId} = action.payload;
  const endPoint = userApi + '/' + userId + '/activate/' + verifyCode;
  const {response, error} = yield call(apiCall, endPoint, 'put');
  if (response && response.data && response.data.status === 'success') {
    yield put(actions.postEmailVerificationSuccess({token: response.data.data.token}));
  } else {
    yield put(actions.postEmailVerificationFail({error: response.data.message}));
  }
}

function* postRegisterUserDetailSaga(action) {
  const {first_name, last_name, password, company, userId, token} = action.payload;
  const endPoint = userApi + '/' + userId + '/register';
  const postObj: any = {
    field: {
      first_name,
      last_name,
      company,
      password,
      token,
    },
  };

  const {response, error} = yield call(apiCall, endPoint, 'put', postObj);
  if (response && response.data && response.data.status === 'Success') {
    yield put(actions.postRegisterUserDetailSuccess({}));
  } else {
    yield put(actions.postRegisterUserDetailFail({error: response.data.message}));
  }
}

export function* registerSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actions.postRegisterEmailStart.type, postRegistrationEmailSaga);
  yield takeEvery(actions.postEmailVerificationStart.type, postEmailVerificationSaga);
  yield takeEvery(actions.postRegisterUserDetailStart.type, postRegisterUserDetailSaga);
}
