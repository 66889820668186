import * as Yup from 'yup';
import * as _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack5';
import {Link as RouterLink} from 'react-router-dom';
import {useFormik, Form, FormikProvider} from 'formik';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import {LoadingButton} from '@material-ui/lab';
// routes
import {PATH_AUTH} from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import {MIconButton} from '../../../components/@material-extend';

// ----------------------------------------------------------------------

const LoginForm: React.FC = () => {
  const {onLogin, isAuthenticated, error} = useAuth();
  const isMountedRef = useIsMountedRef();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      onLogin(values.email, values.password);
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    if (isAuthenticated) {
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: function close(key) {
          return (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          );
        },
      });

      if (isMountedRef.current) {
        setSubmitting(false);
        resetForm();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (error !== null) {
      // enqueueSnackbar('Login fail', {
      //   variant: 'error',
      //   action: function close(key) {
      //     return (
      //       <MIconButton size="small" onClick={() => closeSnackbar(key)}>
      //         <Icon icon={closeFill} />
      //       </MIconButton>
      //     );
      //   },
      // });

      if (isMountedRef.current) {
        setSubmitting(false);
        setErrorMessage(error);
      }
    } else {
      if (isMountedRef.current) {
        setSubmitting(false);
        setErrorMessage('');
      }
    }
  }, [error]);

  const handleShowPassword = () => {
    setShowPassword(show => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {_.isString(errorMessage) && errorMessage !== '' && (
            <Alert severity="error">{errorMessage}</Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{my: 2}}
        >
          <FormControlLabel
            control={
              <Checkbox {...getFieldProps('remember')} checked={values.remember} />
            }
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
