import React from 'react';
import {useLocation} from 'react-router-dom';
// material
import {useTheme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {ReactComponent as LogoDb} from '../assets/SIR_logo_db.svg';

// ----------------------------------------------------------------------

type LogoPropType = {
  sx?: {[x: string]: any};
};

const LogoDashboard: React.FC<LogoPropType> = ({sx}): JSX.Element => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{width: 140, ...sx}}>
      <LogoDb />
    </Box>
  );
};

export default LogoDashboard;
