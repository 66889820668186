import React from 'react';
// material
import {useMediaQuery, Theme, Breakpoint} from '@material-ui/core';
// ----------------------------------------------------------------------

type MHiddenType = {
  width:
    | 'xsDown'
    | 'smDown'
    | 'mdDown'
    | 'lgDown'
    | 'xlDown'
    | 'xsUp'
    | 'smUp'
    | 'mdUp'
    | 'lgUp'
    | 'xlUp';
  [x: string]: any;
};

const MHidden: React.FC<MHiddenType> = ({width, children}) => {
  const breakpoint = width.substring(0, 2);

  const hiddenUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint as Breakpoint)
  );
  const hiddenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint as Breakpoint)
  );

  if (width.includes('Down')) {
    return hiddenDown
      ? ((<></>) as React.ReactElement<any>)
      : (children as React.ReactElement<any>);
  }

  if (width.includes('Up')) {
    return hiddenUp
      ? ((<></>) as React.ReactElement<any>)
      : (children as React.ReactElement<any>);
  }

  return <></>;
};

export default MHidden;
