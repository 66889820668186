import axios, {AxiosRequestConfig, CancelToken, Method} from 'axios';
import {setSession} from '../utils/jwt';
import {store} from '../store';
import {actions as authActions} from 'store/auth/slice';

interface ConfigWithRetryOptions extends AxiosRequestConfig {
  retryOptions?: {retry: number; retryDelay: number};
}
interface RetryOptions {
  retry: number;
  retryDelay: number;
}

const setHeaders = () => {
  if (window.localStorage.getItem('accessToken') !== null) {
    return {field: `Bearer ${window.localStorage.getItem('accessToken')}`};
  }

  return {};
};

const apiCall = (
  endPoint: string,
  method: Method,
  data: JSON | FormData | undefined = undefined,
  cancelToken?: CancelToken,
  retryOptions?: RetryOptions
): Promise<any> => {
  return axios({
    method,
    url: endPoint,
    data: data,
    cancelToken: cancelToken,
    retryOptions: retryOptions,
    headers: setHeaders(),
  } as ConfigWithRetryOptions)
    .then(response => ({response}))
    .catch(error => {
      return {error: JSON.parse(JSON.stringify(error))};
    });
};

// intercept the request
axios.interceptors.request.use(config => {
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config;
});

// intercept the response
axios.interceptors.response.use(
  response => {
    if (
      response.data &&
      response.data.status &&
      response.data.status === 'fail' &&
      response.data.message &&
      response.data.message === 'Invalid token.'
    ) {
      // this is used for all other browser tabs of the app
      // if it is null, will redirect user to login page
      setSession(null);

      // this is used for the current browser tab that triggered the log out issue.
      store.dispatch(authActions.triggerLogOutBecauseOfInvalidAccessToken());
    }
    return response;
  },
  function axiosRetryInterceptor(error) {
    const axiosConfig = error.config;
    const retryConfig = error.config.retryOptions;
    const status = error.response ? error.response.status : null;
    // TODO: this is specific used because at the moment the dev api server can't handle too many requests at the same time.
    // if (status === 500) {
    //   return axios(axiosConfig);
    // }

    // If config does not exist or the retry option is not set, reject
    if (!retryConfig || !retryConfig.retry) {
      return Promise.reject(
        (error.response && error.response.data) || 'Something went wrong'
      );
    }

    // Set the variable for keeping track of the retry count
    retryConfig.__retryCount = retryConfig.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (retryConfig.__retryCount >= retryConfig.retry) {
      // Reject with the error
      return Promise.reject(
        (error.response && error.response.data) || 'Something went wrong'
      );
    }

    // Increase the retry count
    retryConfig.__retryCount += 1;

    // Create new promise to handle exponential backoff
    const backoff = new Promise<void>(resolve => {
      setTimeout(function () {
        resolve();
      }, retryConfig.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      return axios(axiosConfig);
    });
  }
);

export {apiCall};

export default axios;
