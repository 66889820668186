import React from 'react';
import {last} from 'lodash';
import PropTypes from 'prop-types';
// material
import {Link as RouterLink} from 'react-router-dom';
import {Typography, Box, Link, Breadcrumbs} from '@material-ui/core';

// ----------------------------------------------------------------------

const Separator = (
  <Box
    component="span"
    sx={{width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled'}}
  />
);

type LinkItemProps = {
  link: {name: string; href?: string; icon?: any};
  isLinkDisabled?: boolean;
};

const LinkItem: React.FC<LinkItemProps> = ({link, isLinkDisabled}) => {
  const {href, name, icon} = link;
  return (
    <Link
      to={href && !isLinkDisabled ? href : ''}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        // color: 'text.primary',
        color: '#081C41',
        fontWeight: 'light',
        '& > div': {display: 'inherit'},
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            '& svg': {width: 20, height: 20},
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
};

type BreadcrumbsProps = {
  links: {name: string; href?: string; icon?: any}[];
  isLinkDisabled?: boolean;
  activeLast?: boolean;
};

const MBreadcrumbs: React.FC<BreadcrumbsProps> = ({
  links,
  activeLast = false,
  isLinkDisabled = false,
  ...other
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentLink = last(links)!.name;

  const listDefault = links.map(link => (
    <LinkItem key={link.name} link={link} isLinkDisabled={isLinkDisabled} />
  ));
  const listActiveLast = links.map(link => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} isLinkDisabled={isLinkDisabled} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // color: 'text.disabled',
            textOverflow: 'ellipsis',
            color: '#081C41',
            fontWeight: 'light',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
};

export default MBreadcrumbs;
